import React from 'react';
import {
    Typography,
    Grid,
    Link
} from '@mui/material';
import { makeStyles } from '@mui/styles'
import { CheckboxField } from '@Components/CustomFields'
import dataStorage from '@s/dataStorage';
import {
    getCurrentAestDate,
    checkSubmitted,
    isBroker,
    isMorrison
} from '@s/helper/utils'
import {
    ACCOUNT_STATUS
} from '@Common/constants'

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.divider,
        margin: 12,
        borderRadius: theme.shape.borderRadius,
        marginTop: 0,
        marginRight: 0
    },
    termsContainer: {
    }
}))

export default function ({ index, formValues, isIndividual }) {
    const classes = useStyles()
    const isSubmitted = checkSubmitted()

    const renderSubmitTime = () => {
        if (!formValues.submit_time) return <React.Fragment />
        return (
            <Grid item xs={12} sm={12}>
                <Typography>
                    {`Agreed at ${getCurrentAestDate(formValues.submit_time)}${formValues.tos_ip ? ' (IP Address ' + formValues.tos_ip + ')' : ''}`}
                </Typography>
            </Grid>
        )
    }

    const renderClientAddress = () => {
        if (isBroker()) {
            return (
                <Grid item xs={12} sm={12}>
                    <CheckboxField name={`applicant_details[${index}].client_address`}
                        label={
                            <div className={classes.termsContainer}>
                                <span>I confirm&nbsp;</span>
                                <Link className='emailLink' target="_blank" href={`mailto:${formValues.applicant_details[0].applicant_email}`}>
                                    {formValues.applicant_details[0].applicant_email}
                                </Link>
                                {
                                    formValues.applicant_details?.[1]?.applicant_email && (formValues.applicant_details?.[1]?.applicant_email !== formValues.applicant_details[0].applicant_email)
                                        ? <React.Fragment>
                                            ,&nbsp;
                                            <Link className='emailLink' target="_blank" href={`mailto:${formValues.applicant_details[1].applicant_email}`}>
                                                {formValues.applicant_details[1].applicant_email}
                                            </Link>
                                        </React.Fragment>
                                        : <React.Fragment />
                                }
                                {
                                    formValues.applicant_details?.[2]?.applicant_email && (formValues.applicant_details?.[2]?.applicant_email !== formValues.applicant_details?.[1]?.applicant_email) && (formValues.applicant_details?.[2]?.applicant_email !== formValues.applicant_details?.[0]?.applicant_email)
                                        ? <React.Fragment>
                                            ,&nbsp;
                                            <Link className='emailLink' target="_blank" href={`mailto:${formValues.applicant_details[2].applicant_email}`}>
                                                {formValues.applicant_details[2].applicant_email}
                                            </Link>
                                        </React.Fragment>
                                        : <React.Fragment />
                                }
                                <span>&nbsp;is my email address.</span>
                            </div>
                        }
                        disabled={isSubmitted}
                    />
                </Grid>
            )
        }
        return (
            <Grid item xs={12} sm={12}>
                <CheckboxField name={`applicant_details[${index}].client_address`}
                    label={
                        <div className={classes.termsContainer}>
                            <span>I confirm&nbsp;</span>
                            <Link className='emailLink' target="_blank" href={`mailto:${formValues.applicant_details[index].applicant_email}`}>
                                {formValues.applicant_details[index].applicant_email}
                            </Link>
                            <span>&nbsp;is my email address.</span>
                        </div>
                    }
                    disabled={isSubmitted}
                />
            </Grid>
        )
    }

    const renderMorrisonTermsAndConditions = () => {
        return (
            <Grid item xs={12} >
                <CheckboxField name={`applicant_details[${index}].terms_confirm`}
                    label={
                        <div className={classes.termsContainer}>
                            <span>I understand and agree to {dataStorage.configAll?.morrison?.producName}&nbsp;
                                <Link className='link' target="_blank" href={`${dataStorage.configAll?.morrison?.termsAndConditions?.link}?${+new Date()}`}>
                                    Equities Terms and Conditions, Sponsorship Agreement,
                                </Link>&nbsp;<Link className='link' target="_blank" href={`${dataStorage.configAll?.morrison?.financialServicesGuide?.link}?${+new Date()}`}>
                                    Financial Services Guide
                                </Link>&nbsp;&&nbsp;<Link className='link' target="_blank" href={`${dataStorage.configAll?.morrison?.privacyPolicy?.link}?${+new Date()}`}>
                                    Privacy Policy
                                </Link>
                            </span>
                        </div>
                    }
                    disabled={isSubmitted}
                />
            </Grid>
        )
    }

    const renderTermsAndConditions = () => {
        if (isMorrison()) return <React.Fragment />

        return (
            <Grid item xs={12} >
                <CheckboxField name={`applicant_details[${index}].morrison_confirm`}
                    label={
                        <div className={classes.termsContainer}>
                            <span>I understand and agree to {dataStorage.config?.producName}&nbsp;
                                <Link className='link' target="_blank" href={`${dataStorage.config?.financialServicesGuide?.link}?${+new Date()}`}>
                                    Financial Services Guide
                                </Link>&nbsp;&&nbsp;<Link className='link' target="_blank" href={`${dataStorage.config?.privacyPolicy?.link}?${+new Date()}`}>
                                    Privacy Policy
                                </Link>
                            </span>
                        </div>
                    }
                    disabled={isSubmitted}
                />
            </Grid>
        )
    }

    const renderMacquarieManagementAccount = () => {
        return (
            <Grid item xs={12} >
                <CheckboxField name={`applicant_details[${index}].macquarie_confirm`}
                    label={
                        <div className={classes.termsContainer}>
                            <span>I wish to open a Macquarie Cash Management Account for the purpose of investing in Equities and other products offered by Morrison Securities. I have read the current&nbsp;</span>
                            <Link className='link' target="_blank" href={`/macquarie-cash-management-account.pdf?${+new Date()}`}>
                                Macquarie Cash Management Account Product Information Statement
                            </Link>
                            <span>&nbsp;and agree to be bound by the terms and conditions set out in the product information statement.</span>
                        </div>
                    }
                    disabled={isSubmitted}
                />
            </Grid>
        )
    }

    const renderTotalConfirm = () => {
        return (
            <Grid item xs={12} >
                <CheckboxField name={`applicant_details[${index}].total_confirm`}
                    label={
                        <div className={classes.termsContainer}>
                            <span>I confirm that I am authorised to provide the personal details presented and I consent to my information being checked with the document issuer or official record holder via third party system for the purpose of confirming my identity.</span>
                        </div>
                    }
                    disabled={isSubmitted}
                />
            </Grid>
        )
    }

    const renderQuantEdgePrivacyStatement = () => {
        return (
            <Grid item xs={12} sm={12}>
                <CheckboxField name={`applicant_details[${index}].quant_edge_privacy_statement_confirm`}
                    label={
                        <div className={classes.termsContainer}>
                            <span>I have read Novus Fintech&nbsp;</span>
                            <Link className='link' target="_blank" href={`/novus-fintech-privacy-policy.pdf?${+new Date()}`}>
                                Privacy Statement
                            </Link>
                        </div>
                    }
                    disabled={isSubmitted}
                />
            </Grid>
        )
    }

    return (
        <React.Fragment>
            <Grid item xs={12} sm={12} style={{ paddingBottom: 0, display: 'block' }}>
                <Typography style={{ fontWeight: 'bold', marginBottom: 12 }}>
                    APPLICANT DECLARATION
                </Typography>
                <Typography gutterBottom>
                    Please read and accept the following terms and conditions to complete your account application.
                </Typography>
            </Grid>
            <Grid container spacing={0} className={classes.container}>
                {renderClientAddress()}
                {renderMorrisonTermsAndConditions()}
                {renderTermsAndConditions()}
                {renderMacquarieManagementAccount()}
                {renderTotalConfirm()}
                {renderQuantEdgePrivacyStatement()}
            </Grid>
            {renderSubmitTime()}
        </React.Fragment>
    )
}
