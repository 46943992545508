import React from 'react';
import { Box, CardMedia, Grid, Typography, Link } from '@mui/material';
import dataStorage from '@s/dataStorage';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
    downloadBtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& a': {
            width: 'fit-content'
        },
        '& img': {
            maxWidth: 224
        }
    }
}))

const DownloadApp = () => {
    const classes = useStyles()
    if (!dataStorage.config.ggPlay && !dataStorage.config.appStore) return <></>
    return (
        <>
            <div className={classes.downloadBtn}>
                {
                    dataStorage.config.ggPlay
                        ? <Link href={dataStorage.config.ggPlay} target='_blank'>
                            <CardMedia
                                component="img"
                                alt="Googleplay download app"
                                image={'/images/download-on-google-playstore.png'}
                            />
                        </Link>
                        : <></>
                }
                {
                    dataStorage.config.appStore
                        ? <Link href={dataStorage.config.appStore} target='_blank'>
                            <CardMedia
                                component="img"
                                alt="Appstore download app"
                                image={'/images/download-on-apple-appstore.png'}
                            />
                        </Link>
                        : <></>
                }
            </div>
            <br />
        </>
    )
}

export default DownloadApp
