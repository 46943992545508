import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormikContext } from 'formik';
import {
    RadioField
} from '@Components/CustomFields';
import {
    TRUSTEE_TYPE_OPTIONS
} from '@Common/constants'
import formModel from '../FormModels/formModels';
import HelpText from '@Components/HelpText'
import LabelText from '@Components/LabelText'

const { formField } = formModel

export default function TrusteeType() {
    const { values: formValues } = useFormikContext();

    return (
        <Grid container spacing={3} style={{ marginTop: 16 }}>
            <Grid item xs={12} sm={12}>
                <HelpText>If you need to make changes during this application, please use the back button at the bottom of the page. Once your application is submitted, we'll be in touch regarding documents we require for verification. This will include an originally certified copy of the trust deed.</HelpText>
            </Grid>
            <Grid item xs={12} sm={12}>
                <LabelText gutterBottom>Trustee type</LabelText>
                <HelpText>If the trustee is a company, please select 'Corporate trustee'.</HelpText>
            </Grid>
            <Grid item xs={12} sm={12}>
                <RadioField required name={formField.trustee_type.name} data={TRUSTEE_TYPE_OPTIONS} fullWidth />
            </Grid>
        </Grid>
    );
}
