import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(theme => ({
  media: {
    marginTop: '15%',
    marginLeft: '30%',
    width: '40%'
  },
  onboarding: {
    width: '50%',
    marginTop: '10%'
  },
  cardBox: {
    width: 250,
    height: 200,
    marginTop: 20
  }
}));

export { useStyle };
