import React from 'react';
import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { TextField, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
    inputContainer: {
        '& input': {
        }
    },
    inputAdornment: {
        '& p': {
            color: 'inherit'
        }
    }
}))

export default function InputWithAdorment(props) {
    const classes = useStyle()
    const { errorText, name, variant = 'outlined', startAdornment, endAdornment, ...rest } = props;
    const [field, meta] = useField(props);
    const [touched, error] = at(meta, 'touched', 'error');
    const { setFieldError, setFieldValue } = useFormikContext();
    function _renderHelperText() {
        if (touched && error) {
            return error;
        }
    }

    const onChange = (event) => {
        const getValueField = event.target.value;
        setFieldValue(field.name, getValueField)
    }

    return (
        <TextField
            variant={variant}
            type="text"
            error={touched && error && true}
            helperText={_renderHelperText()}
            {...field}
            {...rest}
            onChange={onChange}
            className={classes.inputContainer}
            inputProps={{
                maxLength: 24,
                ...rest.inputProps
            }}
            InputProps={{
                startAdornment: <InputAdornment className={classes.inputAdornment} position="start">{startAdornment}</InputAdornment>,
                endAdornment: <InputAdornment className={classes.inputAdornment} position="end">{endAdornment}</InputAdornment>
            }}

        />
    );
}
