import React from 'react'
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import dataStorage from '../dataStorage'

export default function () {
    if (!['UAT', 'DEV'].includes(dataStorage.enviroment)) return <React.Fragment />
    return (
        <AppBar style={{
            background: '#003300',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Toolbar>
                <Typography variant="h6" component="div">
                    {`${dataStorage.enviroment} Environment`}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}
