import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import dataStorage from '@s/dataStorage';
import { makeCapitalizeFunc } from './helper/utils'
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { HelmetProvider } from 'react-helmet-async';

makeCapitalizeFunc()
const initialWebsite = () => {
    const helmetContext = {}
    ReactDOM.render(
        env
            ? <HelmetProvider context={helmetContext}>
                <App />
            </HelmetProvider>
            : <React.Fragment />, document.getElementById('root'));
}

const path = window.location.pathname
let env = path.match(/^\/([^\/]*)/)[1] // eslint-disable-line
if (!dataStorage.listEnv.includes(env)) env = ''
dataStorage.env = env
fetch(`${window.location.origin}/web.json?${+new Date()}`)
    .then(response => response.json())
    .then((configWeb) => {
        document.body.classList.add(`${dataStorage.env}`)
        window.__data = dataStorage
        dataStorage.configAll = configWeb
        dataStorage.config = configWeb && configWeb[env];
        dataStorage.baseUrl = `${dataStorage.config.baseUrl}/${dataStorage.config.version}`
        dataStorage.addressKey = dataStorage.config.addressKey
        // i18n.use(initReactI18next)
        //     .use(LanguageDetector)
        //     .use(HttpApi)
        //     .init({
        //         fallbackLng: 'en',
        //         detection: {
        //             order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
        //             caches: ['cookie']
        //         },
        //         backend: {
        //             loadPath: '/assets/local/{{lng}}/translation.json'
        //         },
        //         react: {
        //             useSuspense: false
        //         }
        //     })

        initialWebsite()
    })
    .catch(error => {
        console.error('get config error', error)
        initialWebsite()
    })

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
