import React, { useState } from 'react';
import {
    Stepper,
    Paper,
    Step,
    StepLabel,
    Button,
    Typography,
    CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles'
import dataStorage from '@s/dataStorage'

const useStyles = makeStyles(theme => {
    return ({
        stepperVertical: {
            display: 'block !important',
            width: '27%',
            maxWidth: 300,
            padding: theme.spacing(3, 0, 2),
            marginRight: theme.spacing(2),
            '& .MuiStepLabel-label': {
                fontSize: 24
            },
            [theme.breakpoints.down('sm')]: {
                display: 'none !important'
            },
            [theme.breakpoints.up('xs')]: {
                marginRight: theme.spacing(3)
            },
            [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(4)
            },
            [theme.breakpoints.up('md')]: {
                marginRight: theme.spacing(6)
            },
            [theme.breakpoints.up('lg')]: {
                marginRight: theme.spacing(8)
            },
            [theme.breakpoints.up('xl')]: {
                marginRight: theme.spacing(12)
            },
            '& .MuiStepConnector-lineVertical': {
                minHeight: 40
            },
            '& .MuiStep-root': {
                '& *': {
                    cursor: 'pointer'
                }
            }
        },
        stepperHorizontal: {
            display: 'none !important',
            '& .MuiStepLabel-label': {
                fontSize: 24
            },
            padding: theme.spacing(3, 0, 2),
            [theme.breakpoints.between(0, 'sm')]: {
                display: 'flex !important'
            },
            [theme.breakpoints.down('sm')]: {
                '& .MuiStepLabel-labelContainer': {
                    display: 'none !important'
                }
            },
            '& .MuiStep-root': {
                '& *': {
                    cursor: 'pointer'
                }
            }
        }
    })
})

const Steps = ({ steps, activeStep, listStepCanPress, onStepClick }) => {
    const classes = useStyles()
    return (
        <React.Fragment>
            <Stepper
                orientation='vertical'
                activeStep={activeStep} className={classes.stepperVertical}>
                {steps.map((label, i) => (
                    <Step key={label} onClick={() => {
                        if (dataStorage.accountStatus) return
                        onStepClick(i)
                    }} className={i === activeStep ? 'stepActive' : ''}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Stepper
                activeStep={activeStep} className={classes.stepperHorizontal}>
                {steps.map((label, i) => (
                    <Step key={label} onClick={() => {
                        if (dataStorage.accountStatus) return
                        onStepClick(i)
                    }} className={i === activeStep ? 'stepActive' : ''}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </React.Fragment>
    )
}

export default Steps
