import dataStorage from '@s/dataStorage'
import {
  getSessionUrl,
  getAuthUrl,
  getRefreshUrl,
  getDecodeUrl,
  getOpeningAccountUrl,
  getBusinessInfoUrl,
  getPerformSearchUrl,
  getMetaDataAddressUrl,
  getDocumentUrl
} from '@s/api/index'
import at from 'lodash/at'
import { getData, postData, putData, postFile, getFile, deleteData } from '@s/api/request'
import CryptoJS from 'react-native-crypto-js';
import {
  DEFAULT_APPLICANT,
  ACCOUNT_TYPE,
  ACCOUNT_STATUS,
  GENDER_OPTIONS,
  TITLE_OPTIONS,
  BSB_OPTION,
  LIST_OF_PIDS,
  CMA_SOURCE_OF_FUNDS_OPTIONS,
  CMA_ACCOUNT_PURPOSE_OPTIONS,
  SOURCE_OF_WEALTH_OPTIONS,
  GOVERNMENT_ID_TYPE_OPTIONS,
  STATE_OF_ISSUE_OPTIONS,
  MEDICARE_CARD_COLOUR_OPTIONS,
  MEDICARE_CARD_COLOUR,
  CMA,
  GENDER_BY_TITLE,
  ACCOUNT_TYPE_PUT_DRAFT
} from '@Common/constants'
import initialValues from '@Containers/PageApplication/Joint/FormModels/formInitial'
import moment from 'moment'
import 'moment-timezone';
import logger from '@s/helper/logger'
import { values } from 'lodash';

const REFRESH_TIME = 15 * 60 * 1000;

export function getGender(title) {
  return GENDER_BY_TITLE[title] || 'OTHER'
}

export function setLang(lang) { }
export function setTheme(theme) { }
export function setFontSize(size) { }

export function checkKycVerify(status) {
  const stateSuccessfully = ['EKYC_VERIFIED', 'EKYC_VERIFIED_ADMINS', 'EKYC_VERIFIED_WITH_CHANGES'];
  return stateSuccessfully.includes(status)
}

export function scrollToTop() {
  const root = document.getElementById('topMark')
  root && root.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
}

export function isBroker() {
  return dataStorage.userType === 1
}

export function isMorrison() {
  return dataStorage.env === 'morrison' || dataStorage.env === 'equix'
}

export function isOtherApplicant() {
  return dataStorage.isSubApplicant && !isBroker()
}

export function checkSubmitted() {
  const applicant = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.activeIndex]
  const isSubmitted = applicant && (applicant.applicant_agreement)
  if (dataStorage.isOperatorSupport) {
    return (dataStorage.accountStatus && dataStorage.accountStatus !== ACCOUNT_STATUS.IN_KYC) || isSubmitted
  }
  return dataStorage.accountStatus || isSubmitted
}

export function getFullAddress(source = {}, prefix = '') {
  if (source[`${prefix}_manual_address`]) {
    const formatUnitNumber = source[`${prefix}_unit_flat_number`] ? source[`${prefix}_unit_flat_number`] + '/' : ''
    return formatUnitNumber +
      source[`${prefix}_street_number`] + ' ' +
      source[`${prefix}_street_name`] + ' ' +
      source[`${prefix}_street_type`].label + ' ' +
      source[`${prefix}_city_suburb`] + ' ' +
      source[`${prefix}_state`].label + ' ' +
      source[`${prefix}_postcode`]
  } else {
    return source[`${prefix}_full_address`] && source[`${prefix}_full_address`].full_address
  }
}

export function getEnv() {
  if (dataStorage.enviroment === 'PROD') return 'PROD'
  return 'DEV'
}

export function getCurrentAestDate(time) {
  const d = new Date()
  const timezone = 10
  const utc = d.getTime() - (d.getTimezoneOffset() * 60000)
  // Current +10 (AEST) time
  const aest = new Date(utc + (3600000 * -timezone))
  // Current +11 (ADST) time
  const adst = new Date(utc + (3600000 * -(timezone + 1)))
  // Set to start of the month DST starts (October)
  // and time of rollover (0200)
  const dstStart = new Date(aest.getFullYear(), (10 - 1), 1, 2, 0, 0, 0)
  // Set to start of the month DST ends (April)
  // and time of rollover (0300)
  const dstEnd = new Date(aest.getFullYear(), (4 - 1), 1, 3, 0, 0, 0);

  // Now, calculate the position of the first Sunday of the start and end month,
  // which is when DST actually starts, or ends.
  // One of 1001 great uses for mod!
  dstStart.setDate(dstStart.getDate() + ((7 - dstStart.getDay()) % 7));
  dstEnd.setDate(dstEnd.getDate() + ((7 - dstEnd.getDate()) % 7));

  // Now we simply check if the current date is between the two regions
  // If ADST is after the end datetime and AEST is before the start datetime,
  // then DST is not in effect. You have to think about this one a little bit...
  // it matters which clock you check, otherwise you might have incorrect 1 hour
  // edge cases around the start/end datetimes.
  // An alternative and perhaps easier way to calculate all this would be to just
  // work everything out against UTC and do away with the need for two Dates.
  // 11:15:03 23/Nov/2021 Australian Eastern Standard Time
  if ((adst.getTime() > dstEnd.getTime()) && (aest.getTime() < dstStart.getTime())) {
    // No DST!
    return `${moment(time).tz('Australia/Sydney').format('HH:mm:ss DD/MMM/YYYY')} Australian Eastern Standard Time`
  }
  // Yes, DST!
  return `${moment(time).tz('Australia/Sydney').format('HH:mm:ss DD/MMM/YYYY')} Australian Eastern Daylight Time`
}

export function mapManualAddress(dist, source, prefix, srcPrefix) {
  dist[`${prefix}_country`] = 'AUSTRALIA'
  const formatUnitNumber = source[`${srcPrefix || prefix}_unit_flat_number`] ? (source[`${srcPrefix || prefix}_unit_flat_number`] + '/') : ''
  const streetType = source[`${srcPrefix || prefix}_street_type`].label || source[`${srcPrefix || prefix}_street_type`]
  const state = source[`${srcPrefix || prefix}_state`].label || source[`${srcPrefix || prefix}_state`]
  // Set full address
  dist[`${prefix}_full_address`] = formatUnitNumber +
    source[`${srcPrefix || prefix}_street_number`] + ' ' +
    source[`${srcPrefix || prefix}_street_name`] + ' ' +
    streetType + ' ' +
    source[`${srcPrefix || prefix}_city_suburb`] + ' ' +
    state + ' ' + source[`${srcPrefix || prefix}_postcode`]
  // Set address line 1
  dist[`${prefix}_address_line_1`] = formatUnitNumber +
    source[`${srcPrefix || prefix}_street_number`] + ' ' +
    source[`${srcPrefix || prefix}_street_name`] + ' ' +
    streetType + ' '
  source[`${srcPrefix || prefix}_street_number`] && (dist[`${prefix}_street_number`] = source[`${srcPrefix || prefix}_street_number`])
  source[`${srcPrefix || prefix}_street_name`] && (dist[`${prefix}_street_name`] = source[`${srcPrefix || prefix}_street_name`])
  streetType && (dist[`${prefix}_street_type`] = streetType)
  source[`${srcPrefix || prefix}_city_suburb`] && (dist[`${prefix}_city_suburb`] = source[`${srcPrefix || prefix}_city_suburb`])
  source[`${srcPrefix || prefix}_postcode`] && (dist[`${prefix}_postcode`] = source[`${srcPrefix || prefix}_postcode`])
  state && (dist[`${prefix}_state`] = state)
  source[`${srcPrefix || prefix}_unit_flat_number`] && (dist[`${prefix}_unit_flat_number`] = source[`${srcPrefix || prefix}_unit_flat_number`])
}

export function mapDataAddress(dist, id, prefix) {
  const source = dataStorage.addressData[id]
  if (!source) return
  dist[`${prefix}_country`] = 'AUSTRALIA'
  source?.unit_flat_number && (dist[`${prefix}_unit_flat_number`] = source.unit_flat_number)
  source?.full_address && (dist[`${prefix}_full_address`] = source.full_address)
  source?.street_number && (dist[`${prefix}_street_number`] = source.street_number)
  source?.street_name && (dist[`${prefix}_street_name`] = source.street_name)
  source?.street_type && (dist[`${prefix}_street_type`] = source.street_type)
  source?.city_suburb && (dist[`${prefix}_city_suburb`] = source.city_suburb)
  source?.postcode && (dist[`${prefix}_postcode`] = source.postcode)
  source?.state && (dist[`${prefix}_state`] = source.state)
  source?.address_line_1 && (dist[`${prefix}_address_line_1`] = source.address_line_1)
  source?.address_line_2 && (dist[`${prefix}_address_line_2`] = source.address_line_2)
}

export function getAddressData(listId = []) {
  return new Promise((resolve, reject) => {
    const lstEncrypted = []
    listId.forEach(id => {
      if (!dataStorage.addressData[id]) {
        lstEncrypted.push(encodeURIComponent(CryptoJS.AES.encrypt(id, dataStorage.addressKey).toString()))
      }
    })
    if (!lstEncrypted.length) {
      resolve()
      return
    }
    const metaUrl = getMetaDataAddressUrl(lstEncrypted.join(','));
    getData(metaUrl).then(res => {
      if (res && res.length) {
        res.forEach(item => dataStorage.addressData[item.id] = item);
      }
      resolve()
    }).catch((er) => {
      resolve()
    })
  })
}

export function mapDataDraftOtherApplicant(obj) {
  try {
    dataStorage.accountType = obj.account_type
    dataStorage.applicationObj = obj
    const initData = clone(initialValues)
    initData.applicant_details = []
    initData.use_existing_CMT_acc = obj.new_cma === CMA.USE_EXISTING
    if (obj.cma_source_of_funds) {
      const cmaSourceOfFunds = CMA_SOURCE_OF_FUNDS_OPTIONS[getEnv()].find(e => e.value === obj.cma_source_of_funds)
      initData.cma_source_of_funds = cmaSourceOfFunds
    }
    if (obj.cma_source_of_funds_desc) {
      initData.cma_source_of_funds_desc = obj.cma_source_of_funds_desc
    }
    if (obj.cma_account_purpose) {
      const cmaAccountPurpose = CMA_ACCOUNT_PURPOSE_OPTIONS[getEnv()].find(e => e.value === obj.cma_account_purpose)
      initData.cma_account_purpose = cmaAccountPurpose
    }
    if (obj.cma_account_purpose_desc) {
      initData.cma_account_purpose_desc = obj.cma_account_purpose_desc
    }
    if (obj.bank_bsb) {
      const bankBsb = BSB_OPTION.find(e => e.value === obj.bank_bsb)
      initData.bank_bsb = bankBsb
    }
    if (obj.bank_account_number) {
      initData.bank_account_number = obj.bank_account_number
    }
    if (obj.bank_account_name) {
      initData.bank_account_name = obj.bank_account_name
    }
    if (obj.settlement_pid) {
      const settlementPid = LIST_OF_PIDS.find(e => e.value === obj.settlement_pid)
      initData.settlement_pid = settlementPid
    }
    if (obj.settlement_existing_hin) {
      initData.settlement_existing_hin = obj.settlement_existing_hin
    }
    obj.applicant_details.forEach((e, i) => {
      if (dataStorage.registerEmail === e.applicant_email) {
        dataStorage.indexApplicant = i
        initData.applicant_details.push({
          ...DEFAULT_APPLICANT,
          // nationality: { label: e.nationality.toCapitalize(), value: e.nationality },
          ekyc_overall_status: e.ekyc_overall_status,
          applicant_id: e.applicant_id,
          // tos_consent: false,
          // tax_exemption: false,
          applicant_email: e.applicant_email,
          // dob: moment(e.dob, 'DD/MM/YYYY').toISOString(),
          // applicant_mobile_phone: e.applicant_mobile_phone ? e.applicant_mobile_phone.split('|')[1].slice(2) : '',
          // same_as_ra: true,
          first_name: e.first_name || '',
          last_name: e.last_name || '',
          // gender: e.gender ? { label: e.gender.toCapitalize(), value: e.gender } : '',
          nationality: e.nationality ? { label: e.nationality.toCapitalize(), value: e.nationality } : '',
          title: e.title ? { label: e.title.toCapitalize(), value: e.title } : ''
          // client_address: false,
          // australian_tax_resident: e.australian_tax_resident,
          // ekyc_aml_consent: e.ekyc_aml_consent,
          // ekyc_overall_status: e.ekyc_overall_status,
          // gbg_verification_id: e.gbg_verification_id,
          // government_id: {
          //   type: e.government_id?.[0].type ? { label: e.government_id?.[0].type.replace(/_/g, ' ').toCapitalize(), value: e.government_id?.[0].type } : '',
          //   number: e.government_id?.[0]?.number,
          //   state_of_issue: e.government_id?.[0].state_of_issue ? { value: e.government_id?.[0].state_of_issue, label: e.government_id?.[0].state_of_issue } : '',
          //   medicare_name_on_card: e.first_name + ' ' + e.last_name,
          //   medicare_card_colour: governmentMedicareCardColour,
          //   medicare_card_expiry_date: e.medicare_card_expiry_date ? moment(e.medicare_card_expiry_date, e.medicare_card_colour === MEDICARE_CARD_COLOUR.GREEN ? 'MM/YYYY' : 'DD/MM/YY').toISOString() : ''
          // },
          // middle_name: e.middle_name,
          // occupation_category: occupationCategory,
          // occupation_type: occupationType,
          // residential_address_city_suburb: e.residential_address_city_suburb,
          // residential_address_postcode: e.residential_address_postcode,
          // residential_address_state: e.residential_address_state,
          // residential_address_street_name: e.residential_address_street_name,
          // residential_address_street_number: e.residential_address_street_number,
          // residential_address_street_type: e.residential_address_street_type,
          // source_of_wealth: e.source_of_wealth ? { label: e.source_of_wealth.replace(/_/g, ' ').toCapitalize(), value: e.source_of_wealth } : ''
        })
      } else {
        initData.applicant_details.push({
          ...DEFAULT_APPLICANT,
          // nationality: { label: e.nationality.toCapitalize(), value: e.nationality },
          applicant_id: e.applicant_id,
          // tos_consent: false,
          // tax_exemption: false,
          applicant_email: e.applicant_email,
          // dob: moment(e.dob, 'DD/MM/YYYY').toISOString(),
          // applicant_mobile_phone: e.applicant_mobile_phone ? e.applicant_mobile_phone.split('|')[1].slice(2) : '',
          // same_as_ra: true,
          first_name: e.first_name || '',
          last_name: e.last_name || '',
          // gender: e.gender ? { label: e.gender.toCapitalize(), value: e.gender } : '',
          title: e.title ? { label: e.title.toCapitalize(), value: e.title } : '',
          applicant_agreement: e.applicant_agreement
        })
      }
    })
    return initData
  } catch (error) {
    logger.error(error, 'mapDataDraftOtherApplicant')
  }
}

export function isMyApplicant(index) {
  return isBroker() || (dataStorage.isSubApplicant || index === 0)
}

export function getFirstField(obj = {}, path = '') {
  const firstField = Object.keys(obj)[0]
  if (firstField && typeof obj[firstField] === 'object') return getFirstField(obj[firstField], firstField)
  else return `${path ? path + '.' : ''}${firstField}`
}

export function autoRefreshToken() {
  if (dataStorage.refreshTokenId) clearInterval(dataStorage.refreshTokenId);
  dataStorage.refreshTokenId = setInterval(refreshAccessToken, REFRESH_TIME)
}

export function refreshAccessToken() {
  logger.log('Renew token', 'refreshAccessToken');
  const data = {
    refreshToken: dataStorage.tokenRefresh,
    deviceID: dataStorage.deviceId
  }
  postData(getRefreshUrl(), { data })
    .then(res => {
      dataStorage.accessToken = res.accessToken;
      autoRefreshToken()
    })
    .catch(() => {
      logger.error('Cannot refresh token. Please relogin')
    })
}

export function formatNumber(number, decimal = 2) {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: decimal }).format(number);
}

export function addEventListenerWindow() {
  window.onbeforeunload = (ev) => {
    ev.preventDefault();
    ev.returnValue = '';
    return ''
  }
}
export function getWhiteLabel() {
  const dataEnv = dataStorage.env;
  if (!dataEnv) return '';
  switch (dataEnv) {
    case 'dev1': return 'EQUIX'
    case 'morrison': return 'Morrison Securities'
    case 'equitystorytrader': return 'Equity Story Trader'
    case 'ricard': return 'Shares.exchange'
    case 'mps': return 'MPS'
    case 'optixtrading': return 'Optix Trading'
    case 'tradeforgood': return 'Trade For Good'
    default: return 'EQUIX'
  }
}

export const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: 24 * 60 * 60 * 1000 * 365 / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000
}
const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' })

export function getRelativeTime(d1, d2 = new Date()) {
  const elapsed = d1 - d2

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const u in units) {
    if (Math.abs(elapsed) > units[u] || u === 'second') {
      return rtf.format(Math.round(elapsed / units[u]), u)
    }
  }
}

export function getPerformSearch(id, referenceNo) {
  return new Promise((resolve, reject) => {
    getData(`${getBusinessInfoUrl()}/${referenceNo}`)
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
}

export function postPerformSearch(id, acn, country = 'AUSTRALIA') {
  return new Promise(async (resolve, reject) => { // eslint-disable-line
    try {
      const url = getPerformSearchUrl(id)
      const response = await postData(url, {
        acn: (acn + '').replace(/\s/g, ''),
        country
      })
      const { verificationId } = response || {}
      if (!verificationId) reject() // eslint-disable-line
      else {
        getData(`${getBusinessInfoUrl()}/${verificationId}`)
          .then(res => resolve(res))
          .catch(err => reject(err))
      }
    } catch (error) {
      reject(error)
    }
  })
}

export function checkBusinessNumber(acn, country = 'AU') {
  return new Promise(async (resolve, reject) => { // eslint-disable-line
    try {
      const url = getBusinessInfoUrl()
      const response = await postData(url, {
        acn: (acn + '').replace(/\s/g, ''),
        country
      })
      const { verificationId } = response || {}
      if (!verificationId) reject() // eslint-disable-line
      else {
        getData(`${url}/${verificationId}`)
          .then(res => resolve({ ...res, verificationId }))
          .catch(err => reject(err))
      }
    } catch (error) {
      reject(error)
    }
  })
}

export function setBranding() {
  const icon = document.querySelector("link[rel*='icon']")
  const favIcon = dataStorage.config?.favicon
  icon && (icon.href = favIcon)
}

export function capitalizeFirstLetter(string = '') {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizer(string = '') {
  if (typeof string !== 'string') return string // dom in dropdown
  const list = string.toLowerCase().split(' ').map(e => e && capitalizeFirstLetter(e))
  return list.join(' ')
}

export function clone(any) {
  try {
    return any ? JSON.parse(JSON.stringify(any)) : any;
  } catch (error) {
    return any
  }
}

export function booleaner(str) {
  if (typeof str === 'boolean') return str
  return !!(str === 'true')
}

export function getMobilePhoneValue(field, prefix) {
  field = (field + '').replace(/\+/g, '');
  return `au|${prefix || ''}${field}`
}

export function makeCapitalizeFunc() {
  // eslint-disable-next-line no-extend-native
  String.prototype.toCapitalize = function () {
    return this.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase()).join(' ')
  }
}

export function getListOrganizationCode(obj) {
  return Object.keys(obj).map(e => ({ label: (e + '').toCapitalize(), value: e }))
}

export function getListBrokerScheduleCode(arr) {
  return arr.map(e => ({ label: `${e.Schedule_Code} - ${e.Schedule_Description}`, value: e.Schedule_Code }))
}

export function getListVettingRule(arr) {
  return arr.map(e => ({ label: e.branch_name, value: e.branch_id }))
}

export function getListBranchCode(obj = {}, organizationCode) {
  if (!obj[organizationCode]) return []
  return (obj[organizationCode].branch_code || []).map(e => ({ label: (e.name + '').toCapitalize(), value: e.code }))
}

export function getListAdvisorCode(obj = {}, organizationCode, branchCode) {
  if (!obj[organizationCode] || !branchCode) return []
  const dicBranchCode = obj[organizationCode].branch_code.reduce((acc, cur) => {
    acc[cur.code] = cur.advisor_code
    return acc
  }, {})
  return (dicBranchCode[branchCode] || []).map(e => ({ label: (e.name + '').toCapitalize(), value: e.code }))
}

export function onLogin(username, password) {
  const sessionId = +new Date()
  const sessionUrl = getSessionUrl(sessionId)
  return new Promise((resolve, reject) => {
    postData(sessionUrl)
      .then(res => {
        const session = res.data
        const data = {
          username: (username + '').trim().toLocaleLowerCase(),
          password: CryptoJS.AES.encrypt(password, session.key).toString(),
          provider: 'paritech',
          storage_token: true,
          session_id: `${sessionId}`
        }
        const authUrl = getAuthUrl()
        postData(authUrl, { data })
          .then(res => {
            if (res.user_type === 'retail') {
              return reject("You do not have permission to use broker's service") // eslint-disable-line
            }
            logger.log(`Login success with ${username}`, 'onLogin')
            dataStorage.refreshToken = res.refreshToken
            dataStorage.deviceId = res.deviceID
            resolve()
          })
          .catch(error => {
            reject(error)
            logger.error(`Login failed with ${username}`, 'onLogin')
          });
      })
      .catch(error => {
        reject(error)
        logger.error('Cannot get session', 'onLogin')
      })
  })
}

export function postPin(pin) { // decode + refresh
  const sessionId = +new Date()
  const sessionUrl = getSessionUrl(sessionId)
  return new Promise((resolve, reject) => {
    postData(sessionUrl)
      .then(res => {
        const session = res.data
        const data = {
          token: dataStorage.refreshToken,
          pin: CryptoJS.AES.encrypt(pin, session.key).toString(),
          session_id: `${sessionId}`
        }
        const decodeUrl = getDecodeUrl()
        postData(decodeUrl, { data })
          .then(res => {
            dataStorage.tokenRefresh = res.token
            autoRefreshToken()
            const data = {
              refreshToken: res.token,
              deviceID: dataStorage.deviceId
            }
            postData(getRefreshUrl(), { data })
              .then(res => {
                dataStorage.accessToken = res.accessToken;
                res.baseUrl && (dataStorage.baseUrl = `https://${res.baseUrl}/${dataStorage.config.version}`)
                getDraft('?status=not_submitted')
                resolve()
              })
              .catch(error => {
                logger.error(error, 'Post Refresh postPin')
                reject(error)
              });
          })
          .catch(error => {
            logger.error(error, 'Post decode postPin')
            reject(error)
          });
      })
      .catch(error => {
        logger.error(error, 'postPin')
        reject(error)
      })
  })
}

export function getNewestCompanyInfo() {
  return new Promise((resolve, reject) => {
    dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
    if (dataStorage.accountType.value) dataStorage.accountType = dataStorage.accountType.value
    if (dataStorage.accountType === ACCOUNT_TYPE.COMPANY) {
      const verificationId = dataStorage.dicDraft.data.formData?.verificationId
      const url = getBusinessInfoUrl()
      getData(`${url}/${verificationId}`)
        .then(res => {
          const formData = { ...dataStorage.dicDraft.data.formData, ...res }
          dataStorage.dicDraft.data.formData = formData
          resolve()
        })
        .catch(error => {
          logger.error(error, 'getNewestCompanyInfo')
          reject(error)
        })
    } else {
      resolve()
    }
  })
}

export function getDraftOfRetailByOperator(draftId, email) {
  return new Promise((resolve, reject) => {
    getData(getOpeningAccountUrl(`/retail-draft?draft_id=${draftId}&email=${email}`))
      .then((res) => {
        if (res) {
          dataStorage.listDraft = [{ ...res, id: draftId }]
          dataStorage.dicDraft = dataStorage.listDraft[0]
          dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
          const applicants = dataStorage.dicDraft?.data?.formData?.applicant_details
          applicants && applicants.forEach((e, i) => {
            if (e.applicant_id === dataStorage.applicantId) {
              dataStorage.activeIndex = i || 0
              dataStorage.indexApplicant = i
              if (i > 0) dataStorage.isSubApplicant = true
              if (checkKycVerify(e.ekyc_overall_status)) {
                dataStorage.isSubmitted = true
              }
              getAccountStatus(dataStorage.equixId).then(res => {
                resolve()
              }).catch(err => {
                logger.error(err, 'getDraftOfRetailByOperator getAccountStatus')
                resolve()
              })
            }
          })
        } else {
          resolve()
        }
      }).catch(error => {
        logger.error(error, 'getDraftOfRetailByOperator')
        dataStorage.goToApp && dataStorage.goToApp()
        reject(error)
      })
  })
}

export function getAccountStatus(equixId) {
  if (!equixId) return
  return new Promise((resolve, reject) => {
    getData(getOpeningAccountUrl(`?equix_id=${equixId}`))
      .then(res => {
        dataStorage.accountStatus = res[0]?.account_status
        resolve()
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getDraft(path, draftId) {
  return new Promise((resolve, reject) => {
    const finish = () => {
      dataStorage.goToApp && dataStorage.goToApp()
      delete dataStorage.goToApp
      resolve()
    }
    getData(getOpeningAccountUrl(`/draft${path || ''}`))
      .then((res) => {
        if (res) {
          if (res.name) {
            dataStorage.listDraft = [{ ...res, id: draftId }]
          } else {
            dataStorage.listDraft = Object.keys(res).map(k => ({ id: k, ...res[k] })).filter(e => e.data && e.data.updated)
          }
          if (dataStorage.listDraft.length === 1) {
            dataStorage.dicDraft = dataStorage.listDraft[0]
            dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0
            dataStorage.equixId = dataStorage.dicDraft?.data?.formData?.equix_id
            dataStorage.applicantId = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.activeIndex].applicant_id
            if (dataStorage.activeIndex > 0) dataStorage.isSubApplicant = true
            else dataStorage.isSubApplicant = false
            dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
            const equixId = dataStorage.dicDraft?.data?.formData?.equix_id
            if (equixId && !dataStorage.activeIndex) {
              getAccountStatus(equixId).then(res => {
                finish()
              }).catch(err => {
                logger.error(err, 'getAccountStatus getDraft')
                finish()
              })
            } else {
              finish()
            }
          } else {
            finish()
          }
        }
      }).catch(error => {
        logger.error(error, 'getDraft')
        dataStorage.goToApp && dataStorage.goToApp()
        reject(error)
      })
  })
}

function autoGenNameDraft(obj = {}, index) {
  let { formData: { applicant_details: applicantDetails, account_type: accountType, rootBusiness } } = obj
  if (!applicantDetails || applicantDetails.length === 0) return accountType
  if (index) applicantDetails = [applicantDetails[index]]
  const names = []
  applicantDetails.forEach((e) => names.push(renderFullname(e)))
  return names.join(', ')
}

function getApplicantId(obj) {
  if (dataStorage.applicantId) return dataStorage.applicantId
  const { formData, index = 0 } = obj
  return formData?.applicant_details?.[index]?.applicant_id
}

export function removeVietnamese(str) {
  // Gộp nhiều dấu space thành 1 space
  str = str.replace(/\s+/g, ' ');
  // loại bỏ toàn bộ dấu space (nếu có) ở 2 đầu của chuỗi
  str = str.trim();
  // bắt đầu xóa dấu tiếng việt  trong chuỗi
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
  str = str.replace(/Đ/g, 'D');
  return str;
}

export function saveDraft(obj, cb) {
  obj.updated = +new Date()
  const { id, name, ...rest } = obj
  const requestFunc = id ? putData : postData
  let url = getOpeningAccountUrl('/draft')
  if (id) {
    const applicantId = getApplicantId(obj)
    if (dataStorage.isOperatorSupport && applicantId) {
      url = getOpeningAccountUrl(`/retail-draft?draft_id=${id}&email=${dataStorage.registerEmail}&equix_id=${dataStorage.equixId}&applicant_id=${applicantId}`)
    } else {
      if (isOtherApplicant() && applicantId) {
        url = getOpeningAccountUrl(`/draft?draft_id=${id}&equix_id=${dataStorage.equixId}&applicant_id=${applicantId}`)
      } else {
        url = getOpeningAccountUrl(`/draft?draft_id=${id}`)
      }
    }
    // let dataFromData = {};
    // const getFormData = rest?.formData
    // if (!ACCOUNT_TYPE_PUT_DRAFT?.[getFormData.account_type]) return;
    // // delete key have characters mailing_address api put Data.
    // for (const x in getFormData) {
    //   if (!(x?.includes('mailing_address') && x != null)) {
    //     dataFromData = { ...dataFromData, [x]: getFormData[x] }
    //   }
    // }
    // rest.formData = dataFromData
    // // **
  } else if (dataStorage.isSubApplicant) {
    url = getOpeningAccountUrl(`/draft?equix_id=${dataStorage.equixId}`)
  }
  const sendData = {
    name: autoGenNameDraft(rest, dataStorage.isSubApplicant && !dataStorage.isOperatorSupport ? obj.index : null),
    data: rest
  }
  return requestFunc(url, sendData)
    .then((res) => {
      if (!id) dataStorage.listDraft.push({ ...sendData, id: res.draft_id })
      if (res.draft_id) {
        dataStorage.dicDraft = {
          ...sendData,
          id: res.draft_id
        }
      }
      cb && cb()
      logger.log('Save draft success', 'saveDraft')
    }).catch(error => {
      logger.error(error, 'saveDraft')
    })
}

export function createDraftOtherApplicant(equixId, applicantId, applicantEmail, data) {
  data.updated = +new Date()
  const url = getOpeningAccountUrl(`/draft?equix_id=${equixId}&applicant_id=${applicantId}&applicant_email=${applicantEmail}`)
  const sendData = {
    data,
    name: autoGenNameDraft(data, data.index)
  }
  return postData(url, sendData)
}

export function getFullName(data) {
  const title = data.title?.label ? data.title.label + ' ' : ''
  return `${title}${data.first_name} ${data.last_name}`
}

export function checkShow(listDepend, formValues) {
  if (typeof listDepend === 'object' && Object.keys(listDepend).length) {
    const keys = Object.keys(listDepend)
    for (let index = 0; index < keys.length; index++) {
      const depend = at(formValues, keys[index])[0] ?? {}
      const value = depend.value || depend
      const refs = listDepend[keys[index]]
      if (Array.isArray(refs)) {
        if (!refs.includes(value)) return false
      } else {
        if (value !== refs) return false
      }
    }
  }
  return true
}

export function getABN(value) {
  const number = (value + '').replace(/\s/g, '').match(/\d/g)?.join('') || ''
  return number
}

export function clearTrashFieldAndMapData(obj = {}, path = '', formValues, formField, skipFields) {
  try {
    Object.keys(obj).forEach(field => {
      const pathField = `${path ? path + '.' : ''}${field}`
      let item = obj[field]
      if (typeof item === 'string') {
        obj[field] = obj[field].trim()
        item = item.trim()
      }
      if (![undefined, null, ''].includes(item)) {
        if (Array.isArray(item)) {
          item.forEach((e, i) => {
            clearTrashFieldAndMapData(e, `${pathField}[${i}]`, e, formField, skipFields)
          })
        } else {
          if (skipFields && skipFields.includes(field)) return
          if (['super_fund_abn', 'company_abn', 'company_acn', 'trust_abn'].includes(field)) {
            const newValue = getABN(item)
            obj[field] = newValue
            item = newValue
          }
          if (typeof item === 'object' && Object.keys(item).length && (!Object.prototype.hasOwnProperty.call(item, 'label') && !Object.prototype.hasOwnProperty.call(item, 'value') && !Object.prototype.hasOwnProperty.call(item, 'full_address'))) return clearTrashFieldAndMapData(item, pathField, formValues, formField, skipFields)
          if (typeof item === 'object' && Object.keys(item).length && Object.prototype.hasOwnProperty.call(item, 'label') && Object.prototype.hasOwnProperty.call(item, 'value')) {
            item = item.value
            obj[field] = item
          }
          if ([undefined, null, ''].includes(item)) {
            delete obj[field]
          } else {
            const fieldModel = at(formField, pathField.replace(/\[\d]/, ''))[0] ?? {}
            if (fieldModel && fieldModel.dependentShow) {
              if (!checkShow(fieldModel.dependentShow, formValues)) {
                delete obj[field]
              }
            }
          }
        }
      } else delete obj[field]
    })
  } catch (error) {
    logger.error(error, 'clearTrashFieldAndMapData')
  }
}
export function getMatchYear(year) {
  if (year == null) return Date.now();

  const ConfigDate = new Date(Date.now());

  ConfigDate.setFullYear(ConfigDate.getFullYear() - year);

  return ConfigDate
}
export const renderApplicants = (applicant_details = []) => {
  try {
    const getListApplicats = applicant_details.map((e, i) => {
      const name = e.first_name && e.last_name ? renderFullname(e) : '';
      return { label: name || `Applicant ${i + 1}`, value: e.applicant_id }
    })
    return [...getListApplicats, { label: 'Other', value: 'OTHER' }]
  } catch (err) {
    return [{ label: 'Other', value: 'OTHER' }]
  }
}
// Tim dc no dang pick theo thang nao

// VD: applicant1 , 2,3
// manual get theo manual
// auto thi return full_address
export const getPickAddress = (formValues = {}) => {
  let result = ''
  if (formValues.pick_address_director) {
    const pickValue = formValues.pick_fund_address?.value
    if (pickValue === 'company_registered_office_address') {
      result = getFullAddress(formValues, 'company_registered_office_address')
    } else if (pickValue === 'company_principal_place_of_business_address') {
      result = getFullAddress(formValues, 'company_principal_place_of_business_address')
    } else {
      const pickApplicant = formValues.applicant_details.find(e => e.applicant_id === pickValue)
      result = getFullAddress(pickApplicant, 'residential_address')
    }
  } else {
    result = getFullAddress(formValues, 'super_fund_address')
  }
  return result
}
export const renderFullname = (Applicants = {}) => {
  const { title = {}, first_name: firstName, middle_name: middleName, last_name: lastName } = Applicants;
  const titleText = (title.label || title) ? (title.label || title) + ' ' : ''
  return middleName ? `${titleText}${firstName} ${middleName} ${lastName}` : `${titleText}${firstName} ${lastName}`
}
export const formatDate = (value, format = 'DD/MM/YYYY') => {
  return moment(value).format(format);
}

export const uploadFile = (deeds, actions, fileStorage, cb) => {
  const formData = new FormData()
  const listIndex = []
  const files = dataStorage[fileStorage]
  const listDelPromise = []
  if (!files) {
    cb?.()
    return
  } else {
    files.forEach((file, index) => {
      if (file) {
        if (!deeds?.[index]?.id) {
          listIndex.push(index)
          formData.append('file', file)
          if (dataStorage[`${fileStorage}_delete`] && dataStorage[`${fileStorage}_delete`][index]) {
            listDelPromise.push(deleteData(getDocumentUrl(dataStorage[`${fileStorage}_delete`][index])))
            dataStorage[`${fileStorage}_delete`].splice(index, 1)
          }
        }
      }
    })
  }
  if (!listIndex.length) {
    cb?.()
    return
  }
  if (listDelPromise.length) {
    Promise.all(listDelPromise).then(res => {
      console.log('Delete old file successfully')
    })
  }
  postFile(getDocumentUrl(), formData).then(res => {
    if (!res || !res?.length) cb?.()
    else {
      res.forEach((id, i) => {
        const index = listIndex[i]
        if (deeds[index]) deeds[index].id = id
      })
      cb?.()
    }
  }).catch(err => {
    actions?.setSubmitting?.(false);
    logger.error(`upload file error: ${err}`)
    dataStorage.showError && dataStorage.showError('Cannot upload file. Please try again!')
  })
}

export const getFileUploaded = (id, cb) => {
  getFile(getDocumentUrl(id)).then(file => {
    if (file) cb?.(file)
    else dataStorage.showError && dataStorage.showError('Cannot read file.')
  }).catch(err => {
    logger.error(`Load file error: ${err}`)
    dataStorage.showError && dataStorage.showError('Cannot load file. Please try again!')
  })
}

export const deleteFile = (id) => {
  deleteData(getDocumentUrl(id)).then(file => {
    console.log('Delete old file successfully')
  }).catch(err => {
    logger.error(`Delete file error: ${err}`)
  })
}
