import {
  createTheme,
  responsiveFontSizes,
  makeStyles
} from '@mui/styles';

const useStyle = makeStyles((theme) => {
  return ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      minHeight: 0,
      backgroundColor: 'transparent'
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      borderRadius: 15,
      minHeight: '400px',
      padding: theme.spacing(2),
      [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3)
      }
    },
    details: {
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      flex: '1 0 auto'
    },
    cover: {
      width: 151
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },
    playIcon: {
      height: 38,
      width: 38
    }
  })
});

export { useStyle };
