import * as Yup from 'yup';
import OnboardingFormModel from './onboardingFormModel';

const { formField } = OnboardingFormModel;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [formField.email.name]: Yup.string().required(`${formField.email.requiredErrorMsg}`).email('Email is invalid')
    // [formField.otp.name]: Yup.string().required(`${formField.otp.requiredErrorMsg}`).max(4, 'OTP must be 4 digits').min(4, 'OTP must be 4 digits')
  })
];
