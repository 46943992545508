import React from 'react';
import {
  Paper,
  Grid,
  Button,
  Typography,
  ListItemIcon,
  ListItemText,
  CircularProgress
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import CompanyIcon from '@mui/icons-material/Business';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment'
import dataStorage from '@s/dataStorage';
import { getOpeningAccountUrl, getBusinessInfoUrl } from '@s/api/index'
import { deleteData, getData } from '@s/api/request'
import ChooseAccountType from './ChooseAccountType';
import { ACCOUNT_TYPE } from '@Common/constants';
import {
  getRelativeTime,
  getDraft,
  getAccountStatus,
  isBroker
} from '@s/helper/utils'
import Footer from '../Footer/Footer'
import Logo from '@Components/Logo'
import logger from '@s/helper/logger'

const useStyles = makeStyles((theme) => ({
  parentContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
      paddingTop: theme.spacing(2)
    },
    background: theme.palette.background.default,
    minHeight: '100vh'
  },
  container: {
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'flex-end',
    width: '100%'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute'
  },
  deleteIcon: {
    display: 'none'
  },
  listItem: {
    '&:hover $deleteIcon': {
      display: 'block'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    '& $wrapper:first-child': {
      marginLeft: 0,
      '& button': {
        marginLeft: 0
      }
    }
  },
  rightButtons: {
    display: 'flex',
    flexDirection: 'row'
  }
}))

export default function ChooseDraft({ ready }) {
  const classes = useStyles();
  const listDraft = React.useRef([])
  const [, updateState] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [already, setAlready] = React.useState(dataStorage.listDraft.length < 2 || ready)
  if (!already) {
    listDraft.current = dataStorage.listDraft.sort((a, b) => b.data.updated - a.data.updated)
  }

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const onDeleteDraft = (event, item, index) => {
    event.stopPropagation()
    if (!item.id) return;
    listDraft.current.splice(index, 1)
    if (index === selectedIndex) {
      if (listDraft.current.length) forceUpdate()
      else continueWithNewDraft()
    } else forceUpdate()
    const url = getOpeningAccountUrl(`/draft?draft_id=${item.id}`)
    deleteData(url)
      .then((res) => {
        logger.log(`Delete draft ${item.name} success`, 'onDeleteDraft')
      }).catch(error => {
        logger.error(error, 'onDeleteDraft')
        dataStorage.showError && dataStorage.showError(error)
      })
  }

  function renderChooseDraftForm() {
    return <React.Fragment>
      <Grid container spacing={3} style={{ flex: 1, paddingTop: 24, paddingBottom: 24 }}>
        <Grid item xs={12} sm={12}>
          {listDraft.current.map((e, index) => {
            const accountType = e.data.formData?.account_type
            return (
              <ListItem
                button
                className={classes.listItem}
                selected={selectedIndex === index}
                key={`${index}`}
                onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemIcon style={{ marginRight: 8 }}>
                  {accountType === ACCOUNT_TYPE.COMPANY ? <CompanyIcon /> : accountType === ACCOUNT_TYPE.JOINT ? <GroupIcon /> : <PersonIcon />}
                </ListItemIcon>
                <ListItemText primary={`${e.name} (Last Update ${moment(e.data.updated).format('DD/MM/YY HH:mm:ss')}, ${getRelativeTime(e.data.updated)})`} />
                {
                  !isBroker()
                    ? <React.Fragment />
                    : <ListItemIcon>
                      <DeleteIcon className={classes.deleteIcon} onClick={(event) => onDeleteDraft(event, e, index)} />
                    </ListItemIcon>
                }
              </ListItem>
            )
          }
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  }

  const continueWithExistedDraft = async () => {
    try {
      dataStorage.dicDraft = listDraft.current[selectedIndex]
      dataStorage.accountType = dataStorage.dicDraft?.data?.formData?.account_type
      if (dataStorage.dicDraft?.data?.index) {
        dataStorage.activeIndex = dataStorage.dicDraft?.data?.index || 0
        dataStorage.isSubApplicant = true
      }
      dataStorage.registerEmail = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.dicDraft?.data?.index]?.applicant_email
      dataStorage.applicantId = dataStorage.dicDraft?.data?.formData?.applicant_details?.[dataStorage.dicDraft?.data?.index]?.applicant_id
      const equixId = dataStorage.dicDraft?.data?.formData?.equix_id
      dataStorage.equixId = equixId
      if (equixId && !dataStorage.isSubApplicant) await getAccountStatus(equixId)
      if (dataStorage.accountType.value) dataStorage.accountType = dataStorage.accountType.value
      if (dataStorage.accountType === ACCOUNT_TYPE.COMPANY) {
        setLoading(true)
        const verificationId = dataStorage.dicDraft.data.formData?.verificationId
        const url = getBusinessInfoUrl()
        getData(`${url}/${verificationId}`)
          .then(res => {
            setLoading(false)
            const formData = { ...dataStorage.dicDraft.data.formData, ...res }
            dataStorage.dicDraft.data.formData = formData
            setAlready(true)
          })
          .catch(() => {
            setLoading(false)
            logger.error('cannot get newest company verification info', 'continueWithExistedDraft')
            setAlready(true)
          })
      } else {
        setAlready(true)
      }
    } catch (error) {
      logger.error(error, 'continueWithExistedDraft')
    }
  }

  const continueWithNewDraft = () => {
    dataStorage.dicDraft = {}
    dataStorage.accountType = null
    dataStorage.equixId = null
    dataStorage.indexApplicant = 0
    dataStorage.isSubApplicant = false
    dataStorage.accountStatus = ''
    setAlready(true)
  }

  const _backChooseDraft = () => {
    const pah = !isBroker() ? '' : '?status=not_submitted'
    getDraft(pah).then(() => {
      setAlready(false)
    }).catch(() => {
      setAlready(false)
    })
  }

  if (already) return <ChooseAccountType backChooseDraft={_backChooseDraft} />

  return (
    <React.Fragment>
      <div className={classes.parentContainer}>
        <Logo onlyDark />
        <div style={{ height: 32 }} />
        <Paper className={classes.container} elevation={15}>
          <Typography component="h1" variant="h5" align="center" style={{ fontWeight: 'bold' }}>
            Select or Create New Draft
          </Typography>
          {renderChooseDraftForm()}

          <div className={classes.buttons}>
            <div className={classes.leftButtons}></div>
            <div className={classes.rightButtons}>
              <div className={classes.wrapper}>
                {
                  !isBroker()
                    ? <React.Fragment />
                    : <Button
                      onClick={() => continueWithNewDraft()}
                      variant="contained"
                      className={classes.button}
                    >
                      {'Create New'}
                    </Button>
                }
                <Button
                  disabled={loading}
                  onClick={() => continueWithExistedDraft()}
                  type='submit'
                  variant="contained"
                  color="primary"
                  className={classes.button}
                >
                  {'Resume'}
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </React.Fragment>
  )
}
