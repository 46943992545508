/* eslint-disable camelcase */
/* eslint-disable multiline-ternary */
import React from 'react';
import { Grid, Typography, Button, Link, CardMedia } from '@mui/material';
import { useFormikContext, FieldArray } from 'formik';
import {
  InputField,
  CheckboxField,
  SelectField,
  DatePickerField,
  AutocompleteField,
  AutocompleteFieldAsync,
  RadioField,
  BooleanRadio,
  PhoneNumberField
} from '@Components/CustomFields';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  EKYC_GOVID_STATUS,
  AUSTRALIAN_TAX_OPTIONS,
  ACCOUNT_TYPE,
  TITLE_OPTIONS,
  GENDER_OPTIONS,
  GOVERNMENT_ID_TYPE,
  GOVERNMENT_ID_TYPE_OPTIONS,
  STATE_OF_ISSUE_OPTIONS,
  MEDICARE_CARD_COLOUR_OPTIONS,
  MEDICARE_CARD_COLOUR,
  MEDICARE_CARD_INDIDUAL_NUMBERS,
  STREET_TYPE,
  OCCUPATION_TYPE,
  OCCUPATION_TYPE_OPTIONS,
  OCCUPATION_CATEGORY_OPTIONS,
  SOURCE_OF_WEALTH_OPTIONS,
  TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS,
  DEFAULT_APPLICANT
} from '@Common/constants'
import moment from 'moment'
import {
  getOpeningAccountUrl,
  getSubmitOtherApplicantUrl
} from '@s/api/index'
import { postData, getData, putData } from '@s/api/request'
import {
  saveDraft,
  getAddressData,
  isMyApplicant,
  mapDataAddress,
  clone,
  getEnv,
  checkKycVerify,
  isBroker,
  isOtherApplicant
} from '@s/helper/utils'
import COUNTRIES from '@Common/countries'
import dataStorage from '@s/dataStorage';
import { makeStyles } from '@mui/styles';
import DeleteApplicationButton from '@Components/DeleteApplicationButton'
import { v4 as uuidv4 } from 'uuid';
import formModel from '../FormModels/formModels';
import ApplicantNote from '@Components/ApplicantNote';

const { formField } = formModel

const useStyle = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderRadius: 10,
    overflow: 'hidden',
    minHeight: '400px',
    display: 'flex',
    // padding: theme.spacing(2),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6)
      // padding: theme.spacing(3)
    },
    backgroundColor: theme.palette.background.paper
  },
  SelectionBox: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: 'transparent'
  },
  cardBox: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper
  },
  cardBox2: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover'
  },
  cardBox3: {
    width: '33%',
    minHeight: '200px',
    padding: theme.spacing(0),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    backgroundSize: 'cover'
  },
  media: {
    marginTop: '15%',
    // height: '60%',
    marginLeft: '30%',
    width: '40% !important'
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  wrapper: {
    margin: theme.spacing(0),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute'
  },
  deleteIcon: {
    display: 'none'
  },
  listItem: {
    '&:hover $deleteIcon': {
      display: 'block'
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0
    }
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    '& $wrapper': {
      '& button': {
        marginRight: 16
      },
      '&:first-child': {
        marginLeft: 0,
        '& button': {
          marginLeft: 0
        }
      }
    }
  },
  rightButtons: {
    display: 'flex',
    flexDirection: 'row'
  },
  governmentIdImage: {
    objectFit: 'contain',
    maxWidth: 600
  },
  link: {
    color: theme.palette.info.dark
  },
  genderContainer: {
    '& .radio-horizontal': {
      display: 'none'
    },
    '& .MuiFormGroup-root': {
      flexDirection: 'row'
    },
    [theme.breakpoints.up('xxl')]: {
      '& .radio-vertical': {
        display: 'none'
      },
      '& .radio-horizontal': {
        display: 'flex'
      }
    }
  }
}));
function ApplicationForm(props, ref) {
  const classes = useStyle();
  const { index: activeIndex } = props
  const { values: formValues, errors, isSubmitting, setFieldValue, setFieldTouched, setSubmitting, submitForm, setTouched, validateForm, touched } = useFormikContext();
  const checkCondition = isMyApplicant(activeIndex);
  const isVerified = checkKycVerify(formValues.applicant_details?.[activeIndex]?.ekyc_overall_status)
  const checkDateFeature = (day) => {
    if (day == null) return Date.now();
    const ConfigDate = new Date(Date.now());
    ConfigDate.setDate(ConfigDate.getDate() + day);
    return ConfigDate
  }

  const getFormatExpireDate = () => {
    const cardColor = formValues.applicant_details[activeIndex]?.government_id?.medicare_card_colour
    if (!cardColor || cardColor.value === MEDICARE_CARD_COLOUR.GREEN) {
      return {
        format: 'MM/yyyy',
        views: ['month', 'year']
      }
    }
    return {
      format: 'dd/MM/yy',
      views: ['date', 'month', 'year']
    }
  }

  const getMatchYear = (year) => {
    if (year == null) return Date.now();
    const ConfigDate = new Date(Date.now());
    ConfigDate.setFullYear(ConfigDate.getFullYear() - year);
    return ConfigDate
  }

  const renderVerification = () => {
    let imagePath = ''
    if (formValues.applicant_details[activeIndex].government_id?.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE) {
      if (formValues.applicant_details[activeIndex].government_id?.state_of_issue?.value) {
        imagePath = `/images/${formValues.applicant_details[activeIndex].government_id.type?.value}_${formValues.applicant_details[activeIndex].government_id?.state_of_issue?.value}.svg`
      }
    } else if (formValues.applicant_details[activeIndex].government_id?.type?.value === GOVERNMENT_ID_TYPE.PASSPORT) {
      imagePath = '/images/passport.svg'
    }
    return (
      <React.Fragment>
        <Grid item container spacing={3} xs={12} sm={6}>
          <Grid item xs={12}>
            <RadioField
              disabled={isVerified}
              required
              name={`applicant_details[${activeIndex}].government_id.type`}
              label={formField.applicant_details.government_id.type.label}
              data={GOVERNMENT_ID_TYPE_OPTIONS}
              fullWidth />
          </Grid>
          <Grid style={{ margin: 16 }}></Grid>
          {
            formValues.applicant_details[activeIndex]?.government_id?.type?.value === GOVERNMENT_ID_TYPE.DRIVER_LICENSE
              ? <Grid item xs={12}>
                <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].government_id.state_of_issue`}
                  label={formField.applicant_details.government_id.state_of_issue.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
              </Grid>
              : <React.Fragment />
          }
          <Grid item xs={12}>
            <InputField
              disabled={isVerified}
              required
              name={`applicant_details[${activeIndex}].government_id.number`}
              label={formField.applicant_details.government_id.number.label[formValues.applicant_details[activeIndex]?.government_id?.type?.value]}
              fullWidth />
          </Grid>
        </Grid>
        <Grid item container spacing={0} xs={12} sm={6}>
          <Grid item xs={12}>
            {
              imagePath
                ? <CardMedia
                  className={classes.governmentIdImage}
                  component="img"
                  alt="Id Type Image"
                  image={imagePath}
                />
                : <React.Fragment />
            }
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

  const renderPersonalInfo = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <AutocompleteField disabled={isVerified} required name={`applicant_details[${activeIndex}].title`} label={formField.applicant_details.title.label} data={TITLE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].first_name`} label={formField.applicant_details.first_name.label} ext={formField.applicant_details.first_name?.ext} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField disabled={isVerified} name={`applicant_details[${activeIndex}].middle_name`} label={formField.applicant_details.middle_name.label} ext={formField.applicant_details.middle_name?.ext} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].last_name`} label={formField.applicant_details.last_name.label} ext={formField.applicant_details.last_name?.ext} fullWidth />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 4 }}>
          <Typography variant='body2' className='textStatic'>Full Name Example: John Peter Leslie Smith</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <DatePickerField
            disabled={isVerified}
            name={`applicant_details[${activeIndex}].dob`}
            label={formField.applicant_details.dob.label}
            required
            inputFormat="dd/MM/yyyy"
            minDate={new Date('1920/1/1')}
            maxDate={getMatchYear(18)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <AutocompleteField disabled={true} required
            helperText='Note: Only Australian Citizens accepted'
            name={`applicant_details[${activeIndex}].nationality`}
            label={formField.applicant_details.nationality.label}
            data={COUNTRIES} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3} className={classes.genderContainer}>
          <RadioField disabled={isVerified} required name={`applicant_details[${activeIndex}].gender`} label={formField.applicant_details.gender.label} data={GENDER_OPTIONS} fullWidth />
          <RadioField disabled={isVerified} isHorizontal required name={`applicant_details[${activeIndex}].gender`} label={formField.applicant_details.gender.label} data={GENDER_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xxl={3}>
          <PhoneNumberField
            disabled={isVerified}
            required
            prefix='04'
            name={`applicant_details[${activeIndex}].applicant_mobile_phone`}
            label={formField.applicant_details.applicant_mobile_phone.label}
            fullWidth />
        </Grid>
        <Grid item xs={12} className='addressContainer'>
          {
            formValues.applicant_details[activeIndex].residential_address_manual_address
              ? <React.Fragment />
              : <AutocompleteFieldAsync
                disabled={isVerified}
                required
                name={`applicant_details[${activeIndex}].residential_address_full_address`}
                label={formField.applicant_details.residential_address_full_address.label}
                fullWidth />
          }
          <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].residential_address_manual_address`} label="Cannot find your address? Manual Input" />
        </Grid>
        {formValues.applicant_details[activeIndex].residential_address_manual_address
          ? <React.Fragment>
            <Grid item xs={6} sm={6}>
              <InputField disabled={isVerified} name={`applicant_details[${activeIndex}].residential_address_unit_flat_number`} label={formField.applicant_details.residential_address_unit_flat_number.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_number`} label={formField.applicant_details.residential_address_street_number.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_name`} label={formField.applicant_details.residential_address_street_name.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_street_type`} label={formField.applicant_details.residential_address_street_type.label} data={STREET_TYPE} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_city_suburb`} label={formField.applicant_details.residential_address_city_suburb.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_state`} label={formField.applicant_details.residential_address_state.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}>
              <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].residential_address_postcode`} label={formField.applicant_details.residential_address_postcode.label} fullWidth />
            </Grid>
            <Grid item xs={6} sm={6}></Grid>
          </React.Fragment>
          : <React.Fragment>
          </React.Fragment>}
        {
          dataStorage.userType === 0 && activeIndex === 0 && !dataStorage.isSubApplicant
            ? <React.Fragment />
            : <React.Fragment>
              <Grid item xs={12} sm={6}>
                <InputField required
                  disabled={isVerified || dataStorage.isOperatorSupport || isOtherApplicant()}
                  inputProps={{ readOnly: isOtherApplicant() }}
                  name={`applicant_details[${activeIndex}].applicant_email`}
                  label={formField.applicant_details.applicant_email.label}
                  fullWidth />
              </Grid>
              <Grid item xs={12} sm={6}></Grid>
            </React.Fragment>
        }
      </React.Fragment>
    )
  }

  const renderFinancialInfo = () => {
    return (
      <React.Fragment>
        {formValues.applicant_details[activeIndex].australian_tax_resident
          ? (
            <React.Fragment>
              <Grid item xs={12} sm={12}>
                <BooleanRadio
                  required
                  disabled={isVerified}
                  name={`applicant_details[${activeIndex}].australian_tax_resident`}
                  label={formField.applicant_details.australian_tax_resident.label}
                  data={AUSTRALIAN_TAX_OPTIONS}
                  fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <BooleanRadio
                  disabled={isVerified}
                  name={`applicant_details[${activeIndex}].tax_exemption`}
                  label={formField.applicant_details.tax_exemption.label}
                  data={AUSTRALIAN_TAX_OPTIONS}
                />
              </Grid>
              {
                formValues.applicant_details[activeIndex].tax_exemption
                  ? <Grid item xs={12} sm={12}>
                    <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].tax_exemption_details`} label={formField.applicant_details.tax_exemption_details.label} data={TAX_EXEMPTION_DETAILS_APPLICANT_OPTIONS} fullWidth />
                  </Grid>
                  : <Grid item xs={12} sm={12}>
                    <InputField
                      disabled={isVerified}
                      name={`applicant_details[${activeIndex}].tfn`}
                      label={formField.applicant_details.tfn.label}
                      helperText={'Note: Quotation of your Australian Tax File Number(s) (TFN) is optional'}
                      fullWidth />
                  </Grid>
              }
            </React.Fragment>)
          : <React.Fragment>
            <Grid item xs={12} sm={12}>
              <BooleanRadio
                required
                disabled={isVerified}
                name={`applicant_details[${activeIndex}].australian_tax_resident`}
                label={formField.applicant_details.australian_tax_resident.label}
                data={AUSTRALIAN_TAX_OPTIONS}
                fullWidth />
            </Grid>
          </React.Fragment>}
        <Grid item xs={12} sm={12}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].source_of_wealth`} label={formField.applicant_details.source_of_wealth.label} data={SOURCE_OF_WEALTH_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].occupation_type`} label={formField.applicant_details.occupation_type.label} data={OCCUPATION_TYPE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <SelectField disabled={isVerified} required name={`applicant_details[${activeIndex}].occupation_category`} label={formField.applicant_details.occupation_category.label} data={OCCUPATION_CATEGORY_OPTIONS[formValues.applicant_details[activeIndex].occupation_type?.value]} fullWidth />
        </Grid>
        {
          formValues.applicant_details[activeIndex].occupation_type?.value === OCCUPATION_TYPE[getEnv()].BUSINESS_OWNER
            ? <React.Fragment>
              <Grid item xs={12} sm={12}>
                <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].business_owner_trading_name`} label={formField.applicant_details.business_owner_trading_name.label} fullWidth />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputField disabled={isVerified} type='number' name={`applicant_details[${activeIndex}].abn_acn_registration_number`} label={formField.applicant_details.abn_acn_registration_number.label} fullWidth />
              </Grid>
              <Grid item xs={6} sm={12}>
                <AutocompleteFieldAsync
                  required
                  disabled={isVerified}
                  name={`applicant_details[${activeIndex}].business_owner_trading_address_full_address`}
                  label={formField.applicant_details.business_owner_trading_address_full_address.label}
                  fullWidth />
              </Grid>
            </React.Fragment>
            : <React.Fragment />
        }
      </React.Fragment>
    )
  }

  const renderApplicant = () => {
    return (
      <React.Fragment>
        <Grid item xs={12} sm={12}>
          <AutocompleteField disabled={isVerified} required name={`applicant_details[${activeIndex}].title`} label={formField.applicant_details.title.label} data={TITLE_OPTIONS} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].first_name`} label={formField.applicant_details.first_name.label} ext={formField.applicant_details.first_name?.ext} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <InputField disabled={isVerified} name={`applicant_details[${activeIndex}].middle_name`} label={formField.applicant_details.middle_name.label} ext={formField.applicant_details.middle_name?.ext} fullWidth />
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField disabled={isVerified} required name={`applicant_details[${activeIndex}].last_name`} label={formField.applicant_details.last_name.label} ext={formField.applicant_details.last_name?.ext} fullWidth />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 4 }}>
          <Typography variant='body2' className='textStatic'>Full Name Example: John Peter Leslie Smith</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <InputField
            disabled={isVerified}
            disabled={dataStorage.userType === 0 && activeIndex === 0}
            required name={`applicant_details[${activeIndex}].applicant_email`}
            label={formField.applicant_details.applicant_email.label}
            helperText={'We will send a reference email to this email address'}
            fullWidth />
        </Grid>
      </React.Fragment>
    )
  }

  const renderConfirm = () => {
    if (!checkCondition) return <React.Fragment />
    return (
      <Grid item xs={12} >
        <CheckboxField disabled={isVerified} name={`applicant_details[${activeIndex}].ekyc_aml_consent`}
          label={
            <div>
              <span>I agree with </span>
              <Link className={classes.link} target="_blank" href={`/ekyc-and-aml-consent.pdf?${+new Date()}`}>
                the eKYC & AML Consent
              </Link>
              <span>.</span>
            </div>
          }
        />
      </Grid>
    )
  }

  return (
    <Grid container spacing={3} key={`joint_applicant_${activeIndex}`}>
      <ApplicantNote />
      {checkCondition ? <>
        {renderPersonalInfo()}
        {renderVerification()}
        {renderFinancialInfo()}
        {renderConfirm()}
      </> : <>
        {renderApplicant()}
      </>}
    </Grid>
  );
}

export default ApplicationForm
