import React from 'react'
import * as MuiIcons from '@mui/icons-material'

export default function ComponentWithIcon({ name, color, style, ...rest }) {
    return (
        <React.Fragment>
            {React.createElement(MuiIcons[name], {
                style: { color, ...style },
                ...rest
            })}
        </React.Fragment>
    )
}
