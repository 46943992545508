import { makeStyles } from '@mui/styles';
export default makeStyles(theme => ({
  appHeader: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5)
    },
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  appbar: {
    background: '#003300',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
