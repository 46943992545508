import React from 'react';
import { Grid, Typography, TextField, Link } from '@mui/material';
import { useFormikContext } from 'formik';
import {
    InputField,
    CheckboxField,
    RadioField,
    BooleanRadio,
    SelectField,
    DatePickerField,
    AutocompleteFieldAsync,
    PhoneNumberField
} from '@Components/CustomFields';
import {
    COMPANY_TYPE_OPTIONS,
    COMPANY_SECTOR_OPTIONS,
    TAX_EXEMPTION_DETAILS_OPTIONS,
    AUSTRALIAN_TAX_OPTIONS,
    STREET_TYPE,
    STATE_OF_ISSUE_OPTIONS
} from '@Common/constants'
import dataStorage from '@s/dataStorage'
import ChessInfo from '@Components/ChessInfo'
import formModel from '../FormModels/formModels';
import { getEnv } from '../../../../helper/utils';
import HelpText from '@Components/HelpText'
import LabelText from '@Components/LabelText'
import HeadText from '@Components/HeadText'
import HelpIcon from '@Components/HelpIcon'

const { formField } = formModel

export default function CompanyDetails() {
    const { values: formValues } = useFormikContext();

    const renderCompanyInfo = () => {
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <InputField required name={formField.company_name.name} label={formField.company_name.label} fullWidth />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SelectField required name={formField.company_type.name} label={formField.company_type.label} data={COMPANY_TYPE_OPTIONS} fullWidth />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <SelectField required name={formField.company_sector.name} label={formField.company_sector.label} data={COMPANY_SECTOR_OPTIONS} fullWidth />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DatePickerField
                        name={formField.company_date_of_incorporation.name}
                        label={formField.company_date_of_incorporation.label}
                        required
                        inputFormat="dd/MM/yyyy"
                        minDate={new Date('1920/1/1')}
                        maxDate={Date.now()}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <InputField required name={formField.company_acn.name} label={formField.company_acn.label} fullWidth />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <InputField name={formField.company_abn.name} label={formField.company_abn.label} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <HeadText>Company Contact Information</HeadText>
                </Grid>
                <Grid item xs={12} className='addressContainer'>
                    {
                        formValues.company_registered_office_address_manual_address
                            ? <React.Fragment />
                            : <AutocompleteFieldAsync
                                required
                                name={formField.company_registered_office_address_full_address.name}
                                label={formField.company_registered_office_address_full_address.label}
                                fullWidth />
                    }
                    <CheckboxField name={formField.company_registered_office_address_manual_address.name} label="Cannot find your address? Manual Input" />
                </Grid>
                {
                    formValues.company_registered_office_address_manual_address
                        ? <React.Fragment>
                            <Grid item xs={6} sm={6}>
                                <InputField name={formField.company_registered_office_address_unit_flat_number.name} label={formField.company_registered_office_address_unit_flat_number.label} fullWidth />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <InputField required name={formField.company_registered_office_address_street_number.name} label={formField.company_registered_office_address_street_number.label} fullWidth />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <InputField required name={formField.company_registered_office_address_street_name.name} label={formField.company_registered_office_address_street_name.label} fullWidth />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <SelectField required name={formField.company_registered_office_address_street_type.name} label={formField.company_registered_office_address_street_type.label} data={STREET_TYPE} fullWidth />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <InputField required name={formField.company_registered_office_address_city_suburb.name} label={formField.company_registered_office_address_city_suburb.label} fullWidth />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <SelectField required name={formField.company_registered_office_address_state.name} label={formField.company_registered_office_address_state.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
                            </Grid>
                            <Grid item xs={6} sm={6}>
                                <InputField required name={formField.company_registered_office_address_postcode.name} label={formField.company_registered_office_address_postcode.label} fullWidth />
                            </Grid>
                            <Grid item xs={6} sm={6}></Grid>
                        </React.Fragment>
                        : <React.Fragment>
                        </React.Fragment>
                }
                <Grid item xs={12} sm={12}>
                    <CheckboxField name={formField.company_same_as_roa.name} label={formField.company_same_as_roa.label} />
                </Grid>
                {
                    formValues.company_same_as_roa
                        ? <React.Fragment />
                        : <React.Fragment>
                            <Grid item xs={12} className='addressContainer'>
                                {
                                    formValues.company_principal_place_of_business_address_manual_address
                                        ? <React.Fragment />
                                        : <AutocompleteFieldAsync
                                            required
                                            name={formField.company_principal_place_of_business_address_full_address.name}
                                            label={formField.company_principal_place_of_business_address_full_address.label}
                                            fullWidth />
                                }
                                <CheckboxField name={formField.company_principal_place_of_business_address_manual_address.name} label="Cannot find your address? Manual Input" />
                            </Grid>
                            {
                                formValues.company_principal_place_of_business_address_manual_address
                                    ? <React.Fragment>
                                        <Grid item xs={6} sm={6}>
                                            <InputField name={formField.company_principal_place_of_business_address_unit_flat_number.name} label={formField.company_principal_place_of_business_address_unit_flat_number.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <InputField required name={formField.company_principal_place_of_business_address_street_number.name} label={formField.company_principal_place_of_business_address_street_number.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <InputField required name={formField.company_principal_place_of_business_address_street_name.name} label={formField.company_principal_place_of_business_address_street_name.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <SelectField required name={formField.company_principal_place_of_business_address_street_type.name} label={formField.company_principal_place_of_business_address_street_type.label} data={STREET_TYPE} fullWidth />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <InputField required name={formField.company_principal_place_of_business_address_city_suburb.name} label={formField.company_principal_place_of_business_address_city_suburb.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <SelectField required name={formField.company_principal_place_of_business_address_state.name} label={formField.company_principal_place_of_business_address_state.label} data={STATE_OF_ISSUE_OPTIONS} fullWidth />
                                        </Grid>
                                        <Grid item xs={6} sm={6}>
                                            <InputField required name={formField.company_principal_place_of_business_address_postcode.name} label={formField.company_principal_place_of_business_address_postcode.label} fullWidth />
                                        </Grid>
                                        <Grid item xs={6} sm={6}></Grid>
                                    </React.Fragment>
                                    : <React.Fragment>
                                    </React.Fragment>
                            }
                        </React.Fragment>
                }
                <Grid item xs={12} sm={12}>
                    <PhoneNumberField
                        maxLength={10}
                        name={formField.company_work_phone.name}
                        label={formField.company_work_phone.label}
                        fullWidth />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <PhoneNumberField
                        prefix='04'
                        name={formField.company_mobile_phone.name}
                        label={formField.company_mobile_phone.label}
                        helperText={'Note: Provide at least one contact phone number'}
                        fullWidth />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <InputField required name={formField.company_email.name} label={formField.company_email.label} fullWidth />
                </Grid>
            </React.Fragment>
        )
    }

    const renderTaxInformation = () => {
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <BooleanRadio
                        tooltip={<div>Some people or organisations are exempt from quoting a TFN in relation to their investments. For details to see if this applies to you, <Link target='_blank' href='https://www.ato.gov.au/print-publications/tfn-and-abn-information-for-savings-accounts-and-investments/?page=8'>see the ATO website.</Link></div>}
                        name={formField.company_tax_exemption.name}
                        label={formField.company_tax_exemption.label}
                        data={AUSTRALIAN_TAX_OPTIONS} fullWidth />
                </Grid>
                {
                    formValues.company_tax_exemption
                        ? <Grid item xs={12}>
                            <SelectField required name={formField.company_tax_exemption_details.name} label={formField.company_tax_exemption_details.label} data={TAX_EXEMPTION_DETAILS_OPTIONS} fullWidth />
                        </Grid>
                        : <Grid item xs={12}>
                            <InputField
                                disabled={formValues.company_tax_exemption}
                                name={formField.company_tfn.name}
                                label={formField.company_tfn.label}
                                helperText={
                                    <>
                                        Note: Quotation of your Australian Tax File Number(s) (TFN) is optional
                                        <HelpIcon
                                            tooltip={"We ask for your TFN (Tax File Number) so we can make sure you are charged tax at the correct rate. You don't have to provide your TFN, but if you do, it can help ensure you don't pay too much tax."}
                                            placement='right'
                                            iconName='Info'
                                        />
                                    </>
                                }
                                fullWidth />
                        </Grid>
                }
            </React.Fragment>
        )
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <HeadText>Company Information</HeadText>
            </Grid>
            {renderCompanyInfo()}
            <Grid item xs={12}>
                <HeadText>Tax Information</HeadText>
            </Grid>
            {renderTaxInformation()}
        </Grid>
    );
}
