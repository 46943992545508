import React from 'react';
import { CardMedia, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles(theme => ({
  bannerSuccessPage: {
    height: 'auto',
    width: '40%',
    minWidth: '220px',
    [theme.breakpoints.up(808 + theme.spacing(3) * 2)]: {
      height: 'auto',
      width: '100%'
    }
  }
}))
const ImageReview = (props) => {
  const classes = useStyles();
  return <Grid item container xs={12} sm={4} direction="row" justifyContent="center" alignItems="center">
    <CardMedia
      className={classes.bannerSuccessPage}
      component="img"
      alt="Application received"
      image={'/images/work-process3.png'}
      title="Application received"
    />
  </Grid>
};

export default ImageReview;
