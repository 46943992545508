import React from 'react'
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/lab'
import dataStorage from '@s/dataStorage';
import { scrollToTop } from '../helper/utils'

export const ERROR_MAPPING = {
    OK: {
        message: '(#20001) Application Submitted.',
        description: 'Successful'
    },
    INVALID_ARGUMENT: {
        message: '(#10032) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Client specified an invalid argument. Check error message and error details for more information.'
    },
    FAILED_PRECONDITION: {
        message: '(#10001) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Request can not be executed in the current system state, such as deleting a non-empty directory.'
    },
    OUT_OF_RANGE: {
        message: '(#10002) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Client specified an invalid range.'
    },
    UNAUTHENTICATED: {
        message: '(#10003) Your session is expired. Please refresh the screen and continue with your application.',
        description: 'Request not authenticated due to missing, invalid, or expired OAuth token.'
    },
    PERMISSION_DENIED: {
        message: '(#10004) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Client does not have sufficient permission. This can happen because the OAuth token does not have the right scopes, the client doesn\'t have permission, or the API has not been enabled.'
    },
    NOT_FOUND: {
        message: '(#10005) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'A specified resource is not found.'
    },
    ABORTED: {
        message: '(#10006) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Concurrency conflict, such as read-modify-write conflict.'
    },
    ALREADY_EXISTS: {
        message: '(#10007) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'The resource that a client tried to create already exists.'
    },
    RESOURCE_EXHAUSTED: {
        message: '(#10008) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Either out of resource quota or reaching rate limiting.'
    },
    CANCELLED: {
        message: '(#10009) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Request cancelled by the client.'
    },
    DATA_LOSS: {
        message: '(#10010) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Unrecoverable data loss or data corruption. The client should report the error to the user.'
    },
    UNKNOWN: {
        message: '(#10011) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Unknown server error. Typically a server bug.'
    },
    INTERNAL: {
        message: '(#10012) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Internal server error. Typically a server bug.'
    },
    NOT_IMPLEMENTED: {
        message: '(#10013) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'API method not implemented by the server.'
    },
    'N/A': {
        message: '(#10014) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Network error occurred before reaching the server. Typically a network outage or misconfiguration.'
    },
    UNAVAILABLE: {
        message: '(#10015) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Service unavailable. Typically the server is down.'
    },
    DEADLINE_EXCEEDED: {
        message: '(#10016) Unfortunately, we cannot process your request right now. Please try again later.',
        description: 'Request deadline exceeded. This will happen only if the caller sets a deadline that is shorter than the method\'s default deadline (i.e. requested deadline is not enough for the server to process the request) and the request did not finish within the deadline.'
    },
    2032: {
        message: 'Your username has been temporarily blocked for security reason. Please contact Equix Support.'
    },
    2062: {
        message: 'Incorrect Password or User Login. Please make sure your details are correct and try again'
    },
    OTP_EXPIRED: {
        message: '(#10018) This email verification code is expired. Please request a new code and try again.'
    },
    INVALID_OTP: {
        message: '(#10021) Incorrect email verification code. Please try again.'
    },
    USED_OTP: {
        message: '(#10020) This email verification code has been used. Please request a new code and try again.'
    },
    OTP_UNAVAILABLE: {
        message: '(#10017) This email already has Advisor access. Please use a different email for onboarding service.'
    },
    INVALID_ENVIRONMENT: {
        message: '(#10022) Unfortunately, we cannot process your request right now. Please try again.'
    },
    INVALID_PASSWORD: {
        message: '(#10023) Incorrect email or password. Please try gain.'
    },
    ALREADY_APPROVED_KYC: {
        message: '(#10024) This application KYC has been already approved.'
    },
    ALREADY_REJECTED: {
        message: '(#10025) This application has been already rejected.'
    },
    INVALID_APPLICANT: {
        message: '(#10027) Unfortunately, we cannot process your request right now. Please try again later.'
    },
    INVALID_APPLICATION: {
        message: '(#10028) Unfortunately, we cannot process your request right now. Please try again later.'
    },
    INVALID_APPLICATION_STATUS: {
        message: '(#10029) Unfortunately, we cannot process your request right now. Please try again later.'
    },
    INVALID_KYC_STATUS: {
        message: '(#10030) Unfortunately, we cannot process your request right now. Please try again later.'
    },
    ALREADY_APPROVED_BANK: {
        message: '(#10026) This application Bank Info has been already approved.'
    },
    INVALID_TOKEN: {
        message: '(#10031) Incorrect email verification code. Please try again.'
    },
    MISSING_BENEFICIARY: {
        message: '(#10033) The Application should have at least one beneficiary.'
    },
    MISSING_BENEFICIAL_OWNER: {
        message: '(#10034) The Application should have at least one beneficial owner.'
    },
    USER_ALREADY_EXISTS: {
        message: '(#10035) This email is already used by another account.'
    },
    INVALID_ROLE_APPLICANT: {
        message: '(#10036) Please select that there is at least one \'Director\' if there is one or more company officers.'
    },
    INVALID_CONTACT_NUMBER_PHONE_COMPANY: {
        message: '(#10037) Provide at least one contact phone number'
    },
    ERROR_AREA_CODE_MOBILE_PHONE: {
        message: '(#10038) Company mobile phone must be started by "04"'
    },
    INVALID_EXTENSION: {
        message: '(#10039) Sorry, we only allow the following format files: .pdf, .docx, .doc, .jpeg'
    },
    FILE_IS_TOO_LARGE: {
        message: '(#10040) Please make sure your file is less than 10MB'
    }
}

const Error = (props) => {
    const [error, setError] = React.useState('')

    const showError = (errorObj = '') => {
        if (!errorObj) return
        scrollToTop()
        if (typeof errorObj === 'string') {
            setError(errorObj)
            return
        }
        const errorCode = errorObj.error_code || errorObj.errorCode
        const msg = ERROR_MAPPING[errorCode] || { message: errorCode }
        setError(msg.message)
    }

    React.useEffect(() => {
        dataStorage.showError = showError
    }, [])

    return (
        <Snackbar open={!!error} onClose={() => setError('')}
            autoHideDuration={null}
            disableWindowBlurListener={true}
            ClickAwayListenerProps={{
                mouseEvent: 'onClick',
                onClickAway: () => setTimeout(() => setError(''), 3000)
            }}
            style={{ zIndex: 9999 }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert elevation={6} variant="filled" severity='error'>
                {error}
            </Alert>
        </Snackbar>
    )
}
export default Error
