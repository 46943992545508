import React from 'react';
import { TextField, CircularProgress, Button, ClickAwayListener } from '@mui/material';
import Autocomplete from '@mui/lab/Autocomplete'
import { at } from 'lodash';
import { useField } from 'formik';
import { getData } from '@s/api/request'
import { getSearchAddressUrl } from '@s/api/index'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import InfoBox from '../InfoBox'
import {
  removeVietnamese
} from '@s/helper/utils'
import logger from '@s/helper/logger'

export default function AutocompleteFieldAsync(props) {
  const { label, data, isValid, variant = 'outlined', ...rest } = props;
  const [field, meta, helper] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const { setValue: setValueForm } = helper;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const timeoutId = React.useRef()
  const isValidRef = React.useRef(props.isValid)

  React.useEffect(() => {
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current)
    }
  }, [])

  function _renderHelperText() {
    if (touched && error) {
      return error;
    }
  }

  const getListSuggestAddress = (text = '') => {
    if (text.length < 2) return
    setLoading(true)
    const url = getSearchAddressUrl(text)
    getData(url).then((res = []) => {
      setOptions(res)
      setLoading(false)
    }).catch((err) => {
      setLoading(false)
      logger.error(err, 'Get List Suggest')
    })
  }

  function onChangeText(e) {
    if (!open) setOpen(true)
    const text = removeVietnamese(e.target.value || '')
    timeoutId.current && clearTimeout(timeoutId.current)
    timeoutId.current = setTimeout(() => getListSuggestAddress(text), 500)
  }

  function _onChange(event, option, reason) {
    if (reason === 'selectOption') {
      isValidRef.current = true
      option.isValid = true
      setValueForm && setValueForm(option)
    }
  }

  // React.useEffect(() => {
  //   if (!open) {
  //     setOptions([]);
  //   }
  // }, [open]);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Autocomplete
          style={{ flex: 1 }}
          {...field}
          {...rest}
          onChange={_onChange}
          disableClearable
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          filterOptions={x => x}
          getOptionLabel={(option) => option.full_address || ''}
          options={options}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              {...field}
              {...rest}
              variant={variant}
              error={touched && error && true}
              helperText={_renderHelperText()}
              onChange={onChangeText}
              label={label}
              // type="search"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
        {
          Object.prototype.hasOwnProperty.call(props, 'isValid')
            ? (isValidRef.current
              ? null
              : <Button
                style={{ marginLeft: 16 }}
                onClick={() => onChangeText({ target: { value: field.value?.full_address || '' } })}
                variant="outlined"
              >
                <ChevronLeft />
                {'Confirm this address'}
              </Button>)
            : null
        }
      </div>
    </ClickAwayListener>
  );
}
