// eslint-disable-next-line import/no-anonymous-default-export
export default {
  formId: 'loginForm',
  formField: {
    username: {
      name: 'onboardingusername',
      label: 'Email*',
      requiredErrorMsg: 'Email is required'
    },
    password: {
      name: 'password',
      label: 'Password*',
      requiredErrorMsg: 'Password is required'
    },
    pin: {
      name: 'pin',
      label: 'PIN*',
      requiredErrorMsg: 'PIN is required',
      lengthErrorMsg: 'PIN length must be 6'
    }
  }
}
