import * as Yup from 'yup';
import LoginFormModel from './loginFormModel';

const { formField } = LoginFormModel;

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  Yup.object().shape({
    [formField.username.name]: Yup.string().required(`${formField.username.requiredErrorMsg}`),
    [formField.password.name]: Yup.string().required(`${formField.password.requiredErrorMsg}`)
  }),
  Yup.object().shape({
    [formField.pin.name]: Yup.number().required(`${formField.pin.requiredErrorMsg}`).test('pin', `${formField.pin.lengthErrorMsg}`, value => (value + '').length === 6)
  })
];
