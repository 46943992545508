import React from 'react';
import MaterialLayout from './containers/Layout/MaterialLayout';
import LoginPage from './containers/PageLogin';
import WelcomePage from './containers/PageWelcome';
import ChooseDraft from './containers/PageApplication';
import OnboardingPage from './containers/PageOnboarding/Forms/OnboardingPage';
import dataStorage, { initialStorage } from '@s/dataStorage';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';
import {
  getOpeningAccountUrl
} from '@s/api/index'
import { CircularProgress } from '@mui/material'
import { getDraft, getDraftOfRetailByOperator, addEventListenerWindow, mapDataDraftOtherApplicant } from '@s/helper/utils'
import { verifyOTP, verifyOTPApplicantOther } from './api'
import { postData, getData } from './api/request'
import Joint from '@Containers/PageApplication/Joint/index';
import Individual from '@Containers/PageApplication/Individual/index';
import { ACCOUNT_TYPE } from './common/constants'
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import Meta from './meta'
import OnboardingModal from './containers/PageApplication/OnboardingModal'

function App() {
  const UrlParams = new URLSearchParams(window.location.search);
  const email = UrlParams.get('email');
  const otp = UrlParams.get('otp');
  const accessToken = UrlParams.get('accessToken');
  const draftId = UrlParams.get('draft_id');
  email && (dataStorage.registerEmail = email)
  const [loading, setLoading] = React.useState(!!(email && (otp || accessToken)));
  const equixId = UrlParams.get('equix_id');
  equixId && (dataStorage.equixId = equixId)
  const applicantId = UrlParams.get('applicantId')
  dataStorage.applicantId = applicantId
  accessToken && (dataStorage.accessToken = accessToken)
  // const { t } = useTranslation();
  // const changeLang = React.useRef(false)
  // const [changelang, setchangelang] = React.useState(false);

  React.useEffect(() => {
    addEventListenerWindow()
    if (dataStorage.accessToken && draftId) {
      let suffix = 'retail'
      if ((equixId + '').toUpperCase().endsWith('A')) {
        dataStorage.userType = 1 // broker, operator
        suffix = 'broker'
      }
      dataStorage.isOperatorSupport = true // broker, operator
      getDraftOfRetailByOperator(draftId, dataStorage.registerEmail).then(res => {
        setLoading(false)
        window.history.replaceState('', '', `${window.location.origin}/${dataStorage.env}/${suffix}`)
      }).catch(() => {
        setLoading(false)
        window.history.replaceState('', '', `${window.location.origin}/${dataStorage.env}/${suffix}`)
      })
    } else {
      if (equixId) {
        dataStorage.isSubApplicant = true
        const bodyData = {
          user_login_id: email,
          otp
        }
        const getUrlOTP = applicantId ? verifyOTPApplicantOther(applicantId) : verifyOTP();
        postData(getUrlOTP, bodyData).then(res => {
          const { application_token: token, account_status: status } = res
          dataStorage.accessToken = token
          getDraft(`?draft_id=${draftId}`, draftId).then(res => {
            setLoading(false)
            window.history.replaceState('', '', `${window.location.origin}/${dataStorage.env}`)
          }).catch(() => {
            setLoading(false)
            window.history.replaceState('', '', `${window.location.origin}/${dataStorage.env}`)
          })
        }).catch(error => {
          setLoading(false)
          window.history.replaceState('', '', `${window.location.origin}/${dataStorage.env}`)
          dataStorage.showError && dataStorage.showError(error)
        })
      } else {
        if (email && otp) {
          const bodyData = {
            user_login_id: email,
            otp
          }
          postData(verifyOTP(), bodyData).then(res => {
            const { application_token: token, account_status: status } = res
            dataStorage.accessToken = token
            getDraft().then(res => {
              setLoading(false)
              window.history.replaceState('', '', `${window.location.origin}/${dataStorage.env}`)
            }).catch(() => {
              setLoading(false)
              window.history.replaceState('', '', `${window.location.origin}/${dataStorage.env}`)
            })
          }).catch(error => {
            setLoading(false)
            window.history.replaceState('', '', `${window.location.origin}/${dataStorage.env}`)
            dataStorage.showError && dataStorage.showError(error)
          })
        }
      }
    }
  }, [])

  if (email && (otp || accessToken)) {
    return (
      <MaterialLayout>
        {
          loading
            ? <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}><CircularProgress /></div>
            : <ChooseDraft already={!!dataStorage.accountType} />
        }
      </MaterialLayout>
    )
  }
  // const chooseLanguage = () => {
  //   const getLang = i18n.language ? (i18n.language === 'en') : null;
  //   const chooselang = getLang ? !getLang : !changeLang.current;
  //   changeLang.current = chooselang
  //   return i18n.changeLanguage(chooselang ? 'en' : 'vn')
  // }

  return (
    <>
      {/* <Meta /> */}
      <MaterialLayout>
        <Router>
          <Switch>
            {/* <Route path={`/`} component={LandingPage} /> */}
            {/* <Route path={`/${dataStorage.env}/retail`} component={OnboardingPage} />
            <Route path={`/${dataStorage.env}/broker`} component={LoginPage} /> */}
            <Route path={`/${dataStorage.env}/`} component={OnboardingPage} />
          </Switch>
        </Router>
      </MaterialLayout >
    </>
  );
}

export default App;
