import moment from 'moment'
import dataStorage from '../dataStorage'
import { getLogUrl } from '../api/index'
import { postData } from '../api/request'
import { v4 as uuidv4 } from 'uuid';
import CryptoJS from 'react-native-crypto-js';

// Log pattern
// <Env(Dev/Uat/Prod)>-<Whitelabel>-<Form>-<Function lỗi nếu có>-<Thông tin device/user/account nếu có>-<Thông tin lỗi>

function sendLogToServer(message, funcName, type = 'info') {
    try {
        let msg = typeof message === 'object' ? JSON.stringify(message) : message;
        const currentTime = moment().format('DD/MM/YYYY HH:mm:ss');
        msg = `WEB - ${currentTime} - ${dataStorage.enviroment} - ${(dataStorage.env + '').toUpperCase()} - ${dataStorage.registerEmail} - ${funcName} - ${msg}`;
        const key = uuidv4();
        const encrypMsg = CryptoJS.AES.encrypt(msg, key).toString();
        const logData = {
            data: encrypMsg,
            id: key,
            type
        }
        postData(getLogUrl(), logData)
    } catch (error) {
        console.error('SEND LOG ERROR: ', error);
    }
}

const error = (msg, funcName) => {
    console.error(msg);
    sendLogToServer(msg, funcName, 'error');
}

const warn = (msg, funcName) => {
    console.warn(msg);
    sendLogToServer(msg, funcName, 'warning');
}

const log = (msg, funcName) => {
    sendLogToServer(msg, funcName, 'info');
}

export default {
    error,
    warn,
    log
}
