import dataStorage from '@s/dataStorage'

export function getPerformSearchUrl(referenceNo) {
  return `${dataStorage.baseUrl}/kyc-greenid/business-verification?primary_business=${referenceNo}`
}

export function getVerificationUrl(verificationId, individualId) {
  return `${dataStorage.baseUrl}/kyc-greenid/register-verification?type=business&verification_id=${verificationId}&individual_id=${individualId}`
}

export function getBusinessInfoUrl() {
  return `${dataStorage.baseUrl}/kyc-greenid/business-verification`
}

export function getCheckEmailExistUrl(email) {
  return `${dataStorage.baseUrl}/user/account-opening/check-user?email=${email}`
}

export function getBrokerDetailUrl() {
  return `${dataStorage.baseUrl}/user/account/config?type=all`
}

export function getVettingRuleUrl() {
  return `${dataStorage.baseUrl}/user/branch`
}

export function getSearchAddressUrl(text) {
  return `${dataStorage.baseUrl}/address-finder/autocomplete?filter=${text}&post_box=0`
}

export function getMetaDataAddressUrl(id) {
  return `${dataStorage.baseUrl}/address-finder/metadata?id=${id}`
}

export function getSessionUrl(id) {
  return `${dataStorage.baseUrl}/auth/session?session_id=${id}`
}

export function getAuthUrl() {
  return `${dataStorage.baseUrl}/auth`
}

export function getLogUrl() {
  return `${dataStorage.baseUrl}/log/data`
}

export function getRefreshUrl() {
  return `${dataStorage.baseUrl}/auth/refresh`
}

export function getDecodeUrl() {
  return `${dataStorage.baseUrl}/auth/decode`
}

export function getOpeningAccountUrl(path) {
  return `${dataStorage.baseUrl}/user/account-opening${path || ''}`
}

export function getSendOTPUrl(email) {
  return `${dataStorage.baseUrl}/user/account-opening/otp?user_login_id=${email}`
}

export function verifyOTP() {
  return `${dataStorage.baseUrl}/user/account-opening/authorise`;
}
export function verifyOTPApplicantOther(idApplicant) {
  return `${dataStorage.baseUrl}/user/account-opening/authorise?applicant_id=${idApplicant}`;
}
export function getSubmitPrimaryApplicantUrl() {
  return `${dataStorage.baseUrl}/user/account-opening/joint-primary-applicant`;
}

export function getSubmitOtherApplicantUrl(EquixId = '') {
  return `${dataStorage.baseUrl}/user/account-opening/joint-other-applicant/${EquixId}`;
}

export function getKycByOperatorUrl(EquixId = '') {
  return `${dataStorage.baseUrl}/user/account-opening/joint/${EquixId}`
}

export function getDocumentUrl(path = 'upload') {
  return `${dataStorage.baseUrl}/user/account-opening/document/${path}`
}
