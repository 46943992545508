import React from 'react';
import { Grid, Typography, TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import {
  InputField,
  CheckboxField,
  RadioField,
  BooleanRadio,
  SelectField
} from '@Components/CustomFields';
import AutocompleteField from '@Components/CustomFields/AutocompleteField'
import {
  CMA_SOURCE_OF_FUNDS_OPTIONS,
  SETTLEMENT_METHOD_OPTIONS,
  CMA_ACCOUNT_PURPOSE_OPTIONS,
  LIST_OF_PIDS,
  BSB_OPTION
} from '@Common/constants'
import dataStorage from '@s/dataStorage'
import ChessInfo from '@Components/ChessInfo'
import formModel from '../FormModels/formModels';
import { getEnv } from '../../../../helper/utils';

const { formField } = formModel

export default function AdditionalInformationForm() {
  const { values: formValues } = useFormikContext();

  return (
    <Grid container spacing={3}>
      <ChessInfo />
      <Grid item xs={12} sm={12}>
        <BooleanRadio
          required
          name={formField.settlement_method.name}
          label={formField.settlement_method.label}
          data={SETTLEMENT_METHOD_OPTIONS}
          fullWidth />
      </Grid>
      <Grid item xs={12}>
        <Typography style={{ fontWeight: 'bold' }}>
          Cash Settlement
        </Typography>
      </Grid>
      {!formValues.use_existing_CMT_acc
        ? <React.Fragment>
          <Grid item xs={12} sm={12}>
            <TextField
              required
              inputProps={{ readOnly: true }}
              variant="outlined"
              label={'Settlement Bank Account'}
              value={'New Macquarie CMA Bank Account'}
              fullWidth
            />
          </Grid>
        </React.Fragment>
        : null}
      {/* HIN transfer */}
      {formValues.settlement_method
        ? <React.Fragment />
        : (
          <React.Fragment>
            <Grid item xs={12} sm={12}>
              <InputField required name={formField.settlement_existing_hin.name} label={formField.settlement_existing_hin.label} fullWidth />
            </Grid>
            <Grid item xs={12} sm={12}>
              <AutocompleteField required name={formField.settlement_pid.name} label={formField.settlement_pid.label} data={LIST_OF_PIDS} fullWidth />
            </Grid>
          </React.Fragment>
        )
      }

      {/* new CMT account */}
      {!formValues.use_existing_CMT_acc
        ? <React.Fragment>
          <Grid item xs={12} sm={12}>
            <SelectField required name={formField.cma_source_of_funds.name} label={formField.cma_source_of_funds.label} data={CMA_SOURCE_OF_FUNDS_OPTIONS[getEnv()]} fullWidth />
          </Grid>
          {
            formValues.cma_source_of_funds?.value === 'OTHER'
              ? <Grid item xs={12} sm={12}>
                <InputField required name={formField.cma_source_of_funds_desc.name} label={formField.cma_source_of_funds_desc.label} fullWidth />
              </Grid>
              : null
          }
          <Grid item xs={12} sm={12}>
            <SelectField required name={formField.cma_account_purpose.name} label={formField.cma_account_purpose.label} data={CMA_ACCOUNT_PURPOSE_OPTIONS[getEnv()]} fullWidth />
          </Grid>
          {
            formValues.cma_account_purpose?.value === 'OTHER'
              ? <Grid item xs={12} sm={12}>
                <InputField required name={formField.cma_account_purpose_desc.name} label={formField.cma_account_purpose_desc.label} fullWidth />
              </Grid>
              : null
          }
        </React.Fragment>
        : null}

      {/* Link existing CMT account */}
      {!formValues.use_existing_CMT_acc
        ? <React.Fragment />
        : <React.Fragment>
          <Grid item xs={12} sm={12}>
            <SelectField required name={formField.bank_bsb.name} label={formField.bank_bsb.label} data={BSB_OPTION} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputField required name={formField.bank_account_number.name} label={formField.bank_account_number.label} fullWidth />
          </Grid>
          <Grid item xs={12} sm={12}>
            <InputField required name={formField.bank_account_name.name} label={formField.bank_account_name.label} fullWidth />
          </Grid>
        </React.Fragment>}
    </Grid>
  );
}
