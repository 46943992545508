import React, { forwardRef, useImperativeHandle } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Modal from '@mui/material/Modal';
// import Paper from '@mui/material/Paper';
import Paper from '@mui/material/Paper'
import {
    ACCOUNT_TYPE
} from '@Common/constants'
import Individual from './Individual'
import Joint from './Joint'
import Company from './Company'
import Trust from './Trust'
import Fund from './Fund'
import Footer from '../Footer/Footer'
import dataStorage from '../../dataStorage';
import Logo from '@Components/Logo'

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down(808 + theme.spacing(3) * 2)]: {
            paddingTop: theme.spacing(2)
        },
        // background: theme.palette.background.default,
        minHeight: '100vh'
    },
    modalContainer: {
        // background: theme.palette.background.paper,
        width: 'calc(100vw - 64px)',
        padding: theme.spacing(5),
        [theme.breakpoints.down(600)]: {
            padding: theme.spacing(2),
            width: 'calc(100vw - 32px)'
        },
        [theme.breakpoints.up(1200)]: {
            padding: theme.spacing(5),
            width: 'calc(100vw - 128px)'
        },
        [theme.breakpoints.up(1500)]: {
            padding: theme.spacing(5),
            width: 'calc(100vw - 256px)'
        }
    }
}))

const OnboardingModal = ({ onBack, backChooseDraft }) => {
    const classes = useStyles()

    const renderFormByAccountType = () => {
        switch (dataStorage.accountType) {
            case ACCOUNT_TYPE.INDIVIDUAL:
                return <Individual backChooseAccountType={onBack} backChooseDraft={backChooseDraft} />
            case ACCOUNT_TYPE.JOINT:
                return <Joint backChooseAccountType={onBack} backChooseDraft={backChooseDraft} />
            case ACCOUNT_TYPE.COMPANY:
                return <Company backChooseAccountType={onBack} backChooseDraft={backChooseDraft} />
            case ACCOUNT_TYPE.TRUST:
                return <Trust backChooseAccountType={onBack} backChooseDraft={backChooseDraft} />
            case ACCOUNT_TYPE.FUND:
                return <Fund backChooseAccountType={onBack} backChooseDraft={backChooseDraft} />
            default:
                return <React.Fragment />
        }
    }

    return (
        <div className={classes.container}>
            <Logo onlyDark />
            <div style={{ height: 32 }} />
            <Paper elevation={15} className={classes.modalContainer}>
                {renderFormByAccountType()}
            </Paper>
        </div>
    );
}

export default OnboardingModal
