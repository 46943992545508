import React from 'react';
import { at } from 'lodash';
import { useField, useFormikContext } from 'formik';
import { TextField, InputAdornment } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles((theme) => ({
  inputContainerPrefix: {
    '& input': {
      paddingLeft: 24
    }
  },
  inputAdornment: {
    position: 'absolute',
    '& .MuiTypography-root': {
      color: 'inherit'
    }
  },
  inputAdornmentDisable: {
    position: 'absolute',
    '& .MuiTypography-root': {
      color: theme.palette.adormentDisable
    }
  }
}))

export default function PhoneNumberField(props) {
  const classes = useStyle()
  const { errorText, name, variant = 'outlined', maxLength = 8, helperText, prefix, ...rest } = props;
  const [field, meta] = useField(props);
  const [touched, error] = at(meta, 'touched', 'error');
  const formik = useFormikContext();
  function _renderHelperText() {
    if (touched && error) {
      return error;
    } else if (helperText) {
      return helperText
    }
  }

  const onChange = (event) => {
    let value = event.target.value;
    if (value.length > maxLength) {
      value = value.slice(-1 * maxLength) // forr autofill invalid phone number
    }
    const validateNumber = new RegExp('^[\\d]{0,' + maxLength + '}$');
    if (!validateNumber.test(value)) return;
    formik.setFieldValue(props.name, value);
  }

  const onPaste = (event) => {
    let value = event.target.value;
    if (value.length > maxLength) value = value.slice(-1 * maxLength) // forr autofill invalid phone number
    const validateNumber = new RegExp('^[\\d]{0,' + maxLength + '}$');
    if (!validateNumber.test(value)) return;
    formik.setFieldValue(props.name, value);
  }

  return (
    <TextField
      variant={variant}
      type="text"
      error={touched && error && true}
      helperText={_renderHelperText()}
      {...field}
      {...rest}
      onChange={onChange}
      onPaste={onPaste}
      className={prefix ? classes.inputContainerPrefix : ''}
      inputProps={{
        maxLength: maxLength,
        ...rest.inputProps
      }}
      InputProps={{
        startAdornment: prefix ? <InputAdornment className={rest.disabled ? classes.inputAdornmentDisable : classes.inputAdornment} position="start">{prefix}</InputAdornment> : null
      }}

    />
  );
}
